import { z } from 'zod';
import { ZReservationItem } from './reservation';
import { ZOutletWithoutRef } from './outlet.base';
import { ZServiceTimingWithoutRef } from './schedule.base';
import { ZBrandWithoutRef } from './brand.base';

export const ZOutlet = ZOutletWithoutRef.extend({
  brand: ZBrandWithoutRef.optional().nullable(),
  reservations: z.array(ZReservationItem).optional().nullable(),
  serviceTimings: z.array(ZServiceTimingWithoutRef).optional().nullable(),
});
export type Outlet = z.infer<typeof ZOutlet>;
