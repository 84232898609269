import { ReservationItem } from '@/common/types/reservation';
import {
  TICKET_TYPE_PRICE,
  TicketTypePriceInt,
} from '@/common/types/ticket.base';
import { assertUnreachable, cloneDate } from '@/common/utils';
import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  isAfter,
  isValid,
  parse,
  parseISO,
} from 'date-fns';
import isNumber from 'lodash/isNumber';
import { PaymentStatus } from '../transaction/transaction-types';
import { Theme } from '@mui/material';

export const checkIsConsecutive = (array: number[]) => {
  return array.every((value, i) => i === 0 || +value === +array[i - 1]! + 1);
};

export const defaultDateTime = parse('12:00:00', 'HH:mm:ss', new Date());

/**
 * @param objTime24HoursBase i.e: {start: '12:00:00', end: '13:00:00'}
 * @returns startDate, endDate
 * if start > end RETURN start = end
 * else RETURN default
 */
export const getValidStartEndTime = ({
  start = '',
  end = '',
}: {
  start: string;
  end: string;
}) => {
  const startDate = parse(start, 'HH:mm:ss', new Date());
  const endDate = parse(end, 'HH:mm:ss', new Date());

  if (!isValid(startDate) || !isValid(endDate)) {
    return {
      start: defaultDateTime,
      end: defaultDateTime,
    };
  }

  return {
    start: isAfter(startDate, endDate) ? endDate : startDate,
    end: endDate,
  };
};

type Nullish<T> = T | null | undefined;
export const totalPaidReservationPayment = ({
  pax,
  price,
  typePrice,
}: {
  pax: Nullish<number>;
  price: Nullish<number>;
  typePrice: Nullish<TicketTypePriceInt>;
}) => {
  if (!isNumber(pax) || !isNumber(price) || !isNumber(typePrice)) return 0;
  switch (typePrice) {
    case TICKET_TYPE_PRICE.PER_BOOKING:
      return price;
    case TICKET_TYPE_PRICE.PER_PAX:
      return price * pax;
    default:
      assertUnreachable(typePrice);
      return 0;
  }
};

export const isNotificationEnable = (r?: ReservationItem) => {
  return Boolean(r && (r.rStatus === 'R::BOOKED' || r.rStatus === 'R::LATE'));
};

type ColorScheme = {
  color: string;
  background: string;
};

export const getStatusScheme = (status: PaymentStatus): ColorScheme => {
  if (status === 'Manual Refund' || status === 'System Refund') {
    return {
      background: 'rgba(9, 90, 188, 0.16)',
      color: '#043387',
    };
  }
  if (status === 'Paid') {
    return {
      background: 'rgba(78, 173, 19, 0.16)',
      color: '#277C09',
    };
  }
  if (status === 'Failed') {
    return {
      background: 'rgba(219, 39, 39, 0.16)',
      color: '#9D132B',
    };
  }
  if (status === 'Guaranteed') {
    return {
      background: 'rgba(255, 149, 2, 0.16)',
      color: '#B75B01',
    };
  }
  if (status === 'Manual Charge' || status === 'System Charge') {
    return {
      background: 'rgba(78, 173, 19, 0.16)',
      color: '#277C09',
    };
  }
  if (status === 'Manual No Charge' || status === 'System No Charge') {
    return {
      background: 'rgba(219, 39, 39, 0.16)',
      color: '#9D132B',
    };
  }
  if (status === 'Pending') {
    return {
      background: 'rgba(255, 149, 2, 0.16)',
      color: '#B75B01',
    };
  }
  if (status === 'Expired') {
    return {
      background: 'rgba(145, 158, 171, 0.16)',
      color: '#637381',
    };
  }

  return {
    background: 'rgba(145, 158, 171, 0.16)',
    color: '#637381',
  };
};

export const calculateTimeDifference = (expireDateTime: string) => {
  const expirationDate = parse(
    expireDateTime,
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    new Date()
  );
  const currentDate = cloneDate(new Date());

  const timeExpire = expirationDate.getTime() - currentDate.getTime();
  const hoursDifference = differenceInHours(expirationDate, currentDate);
  const minutesDifference =
    differenceInMinutes(expirationDate, currentDate) % 60;

  return {
    timeExpire,
    hoursDifference,
    minutesDifference,
  };
};

export const getReservationStartEndTime = (
  item: ReservationItem
): { start: Date; end: Date } => {
  const start = item.seatedAt
    ? parseISO(item.seatedAt)
    : parse(
        `${item.reservationDate} ${item.reservationTime}`,
        'yyyy-MM-dd HH:mm:ss',
        new Date()
      );

  const diningInterval =
    item.diningInterval || item.serviceTiming?.diningInterval || 0;
  const end = item.departedAt
    ? parseISO(item.departedAt)
    : addSeconds(start, diningInterval);
  return { start, end };
};

export const getReservationSeatedDepartedTime = (r: ReservationItem) => {
  const seated = r.seatedAt ? parseISO(r.seatedAt) : null;
  const departed = r.departedAt ? parseISO(r.departedAt) : null;
  return { seated, departed };
};

export const getMultiTableReservationChipColor = (
  reservation: ReservationItem,
  theme: Theme
) => {
  const colors: {
    chipColor: 'primary' | 'info' | 'warning' | 'success' | 'error';
    background: string;
  }[] = [
    {
      chipColor: 'primary',
      background: theme.palette.primary.main_12,
    },
    { chipColor: 'info', background: theme.palette.info.main_12 },
    { chipColor: 'warning', background: theme.palette.warning.main_12 },
    { chipColor: 'success', background: theme.palette.success.main_12 },
    { chipColor: 'error', background: theme.palette.error.main_12 },
  ];
  const sum = reservation.id.split('').reduce((acc, it) => {
    return acc + it.charCodeAt(0);
  }, 0);
  return colors[sum % colors.length];
};

export const getSegmentType = (segmentName: string) => {
  const lowerCaseSegmentName = segmentName.toLowerCase();
  switch (lowerCaseSegmentName) {
    case 'first time visitor':
      return 'FIRST_TIME_VISITOR';
    case 'repeat visitor':
      return 'REPEAT_VISITOR';
    case 'regular customer':
      return 'REGULAR_CUSTOMER';
    case 'cross diners':
      return 'CROSS_DINERS';
    case 'at risk':
      return 'AT_RISK';
    case 'best customers':
      return 'BEST_CUSTOMERS';
    case 'promising':
      return 'PROMISING';
    case 'lapsed':
      return 'LAPSED';
    case 'window shoppers':
      return 'WINDOW_SHOPPERS';
    default:
      return null;
  }
};

export const getTimeDifference = (lastVisitedDateTime: number) => {
  if (lastVisitedDateTime === null) {
    return null;
  }

  const currentDate = new Date();
  const lastVisitedDate = parse(String(lastVisitedDateTime), 'T', new Date());

  const timeDifferenceYears = differenceInYears(currentDate, lastVisitedDate);
  const timeDifferenceMonths = differenceInMonths(currentDate, lastVisitedDate);
  const timeDifferenceDays = differenceInDays(currentDate, lastVisitedDate);
  const timeDifferenceHours = differenceInHours(currentDate, lastVisitedDate);
  const timeDifferenceMinutes = differenceInMinutes(
    currentDate,
    lastVisitedDate
  );

  if (timeDifferenceMinutes < 60) {
    return `${timeDifferenceMinutes} minute${
      timeDifferenceMinutes === 1 ? '' : 's'
    }`;
  } else if (timeDifferenceHours < 24) {
    return `${timeDifferenceHours} hour${timeDifferenceHours === 1 ? '' : 's'}`;
  } else if (timeDifferenceDays < 30) {
    return `${timeDifferenceDays} day${timeDifferenceDays === 1 ? '' : 's'}`;
  } else if (timeDifferenceMonths < 12) {
    return `${timeDifferenceMonths} month${
      timeDifferenceMonths === 1 ? '' : 's'
    }`;
  } else {
    return `${timeDifferenceYears} year${timeDifferenceYears === 1 ? '' : 's'}`;
  }
};
