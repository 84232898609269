import { z } from 'zod';

export const ZSegmentItem = z.object({
  id: z.string(),
  version: z.string().nullish().optional(),
  seqNo: z.string().nullish().optional(),
  primaryTerm: z.string().nullish().optional(),
  entityId: z.number().nullish().optional(),
  timestamp: z.number().nullable().optional(),
  createdAt: z.number().nullable().optional(),
  updatedAt: z.number().nullable().optional(),
  createdBy: z
    .object({
      id: z.string(),
      name: z.string(),
      type: z.string(),
    })
    .nullable()
    .optional(),
  updatedBy: z
    .object({
      id: z.string(),
      name: z.string(),
      type: z.string(),
    })
    .nullable()
    .optional(),
  predicate: z
    .object({
      type: z.string(),
      operator: z.string().nullable().optional(),
      field: z.string().nullable().optional(),
      value: z.string().nullable().optional(),
      timeZone: z.string().nullable().optional(),
      subPredicates: z
        .array(
          z.object({
            type: z.string(),
            field: z.string().nullable().optional(),
            operator: z.string().nullable().optional(),
            value: z.string().nullable().optional(),
            timeZone: z.string().nullable().optional(),
          })
        )
        .nullable()
        .optional(),
    })
    .nullable()
    .optional(),
  accountId: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  desc: z.string().nullable().optional(),
  type: z.string().nullable().optional(),
  iconId: z.string().nullable().optional(),
  isSystemSegment: z.boolean(),
  groupLevel: z.string().nullable().optional(),
  groupId: z.string().nullable().optional(),
  categories: z.array(z.string()).nullable().optional(),
  isTimeBasedSegment: z.any().nullable().optional(),
});

export type SegmentItem = z.infer<typeof ZSegmentItem>;

export const ZReservationSegmentItem = z.array(
  z.object({
    id: z.string(),
    segments: z.array(ZSegmentItem).nullable().optional(),
    tags: z.array(z.string()).optional().nullable(),
    allTransactionsTotalValue: z.number().nullable().optional(),
    reservationLastVisitedDate: z.number().nullable().optional(),
  })
);

export type ReservationSegmentItem = z.infer<typeof ZReservationSegmentItem>;
