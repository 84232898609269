import { z } from 'zod';

export const ZOutletEntitlement = z.object({
  featureCode: z.string(),
  actionsRequired: z.string().nullable(),
  enabled: z.boolean(),
});

export const ZOpeningHoursItem = z.object({
  id: z.string(),
  outletId: z.string(),
  dayOfWeek: z.string(),
  startTime: z.string().nullable(),
  endTime: z.string().nullable(),
  createdTimestamp: z.string().nullable(),
  modifiedTimestamp: z.string().nullable(),
});

export type OutletEntitlement = z.infer<typeof ZOutletEntitlement>;

export const ZOutletWithoutRef = z.object({
  id: z.string(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  autoCompleteString: z.string().nullable(),
  benchmarkNumOfCovers: z.number().nullable(),
  benchmarkNumOfReservations: z.number().nullable(),
  brandId: z.string(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  countryCode: z.string(),
  createdTimestamp: z.string().nullable(),
  defaultAllowEmailNotify: z.boolean(),
  defaultAllowSmsNotify: z.boolean(),
  defaultConfirmationNotify: z.boolean(),
  defaultReminderNotify: z.boolean(),
  defaultAutomatedCallNotify: z.boolean(),
  directionLink: z.string().nullable(),
  email: z.string().nullable(),
  enableEmail: z.boolean(),
  enableSMS: z.boolean(),
  enableAutomatedCall: z.boolean(),
  enabled: z.number(),
  enabledPaymentDinein: z.boolean(),
  forwardingAddress: z.string().nullable().optional(),
  lat: z.union([z.string(), z.number()]).nullable(),
  lng: z.union([z.string(), z.number()]).nullable(),
  linkMap: z.string().nullable(),
  liteVersion: z.number(),
  maxPartySize: z.number().nullable().optional(),
  maxPaxPerReservation: z.number().nullable().optional(),
  minPartySize: z.number().nullable().optional(),
  modifiedTimestamp: z.string().nullable(),
  oddleEntityId: z.string().nullable(),
  omsOutletId: z.string().nullable().optional(),
  onlineConfirmations: z.boolean(),
  onlineReminder: z.boolean(),
  operationHours: z.string().nullable(),
  outletAddress: z.string().nullable(),
  outletColorLogo: z.string().nullable(),
  outletCoverImage: z.string().nullable(),
  outletLogo: z.string().nullable(),
  outletMenuItemView: z.string().nullable(),
  outletName: z.string().nullable(),
  outletReceiptFooter: z.string().nullable(),
  outletReceiptFooterLogo: z.string().nullable(),
  outletReceiptLogo: z.string().nullable(),
  permissionOutlet: z.number(),
  phone: z.string().nullable(),
  postal: z.string().nullable(),
  reservationAutoSms: z.boolean().nullable(),
  reservationCancellationPolicy: z.string().nullable(),
  reservationDiningInterval: z.number().nullable(),
  reservationGracePeriod: z.number().nullable(),
  reservationIntervalTime: z.number().nullable(),
  reservationLeadTime: z.number().nullable(),
  reservationPolicy: z.string().nullable(),
  reservationSendEmailCopy: z.boolean().nullable(),
  state: z.string().nullable(),
  reservationSendEmailCardGuarantee: z.boolean().nullish(),
  optionAutoReservationStatus: z.number().nullish(),
  confirmationNotificationEnabled: z.boolean().nullish(),
  entitlements: z.array(ZOutletEntitlement).nullish(),
  openingHours: z.array(ZOpeningHoursItem).default([]),
  typeShowTimeslots: z.number().nullish(),
});
