import { ZReservationItem } from '@/common/types/reservation';
import { z } from 'zod';

export const GetReservationsResponse = z.object({
  data: z.array(ZReservationItem),
  total: z.number(),
  totalPax: z.number(),
});
export type GetReservationsResponse = z.infer<typeof GetReservationsResponse>;

export const GetReservationsParams = z.object({
  serviceTimingId: z.string().optional(),
  date: z.string().optional(),
  keysearch: z.string().optional(),
  status: z.number().gte(1).lte(6).optional(),
  limit: z.number().optional(),
  page: z.number().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
});
export type GetReservationsParams = z.infer<typeof GetReservationsParams>;

export const AddWalkInPayload = z.object({
  numberOfAdults: z.number(),
  numberOfChildren: z.number(),
  extraAdult: z.number().optional().nullable(),
  extraChildren: z.number().optional().nullable(),
  customerId: z.string(),
  email: z.string(),
  customerPhone: z.string(),
  customerFirstName: z.string(),
  customerLastName: z.string(),
  salutation: z.string(),
  notes: z.string(),
  staffNotes: z.string(),
  occasions: z.string(),
  language: z.string(),
  canSendEmailMarketing: z.boolean(),
  notesDiner: z.string(),
  diningInterval: z.number().optional(),
  tableIds: z.array(z.string()).optional(),
  otherOccasions: z.string().optional().nullable(),
  tagIds: z.array(z.string()).optional().nullable(),
});

export const AddReservationPayload = AddWalkInPayload.omit({
  customerPhone: true,
  customerFirstName: true,
  customerLastName: true,
}).extend({
  reservationDate: z.string(),
  reservationTime: z.string(),
  allowEmailNotify: z.boolean(),
  allowSmsNotify: z.boolean(),
  allowAutomatedCallNotify: z.boolean(),
  phone: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  serviceTimingId: z.string().optional(),
  allowConfirmationNotify: z.boolean().optional().nullable(),
  allowReminderNotify: z.boolean().optional().nullable(),
  language: z.string().optional().nullable(),
  canSendEmailMarketing: z.boolean().optional().nullable(),
  notesDiner: z.string(),
  ticketId: z.string().optional().nullable(),
  paymentType: z.string().optional().nullable(),
  expireTime: z.number().optional().nullable(),
  paymentStatus: z.string().optional().nullable(),
  notePaymentRequest: z.string().optional().nullable(),
  tagIds: z.array(z.string()).optional().nullable(),
});
export type AddReservationPayload = z.infer<typeof AddReservationPayload>;

export const UpdateReservationResponse = z.object({
  success: z.boolean(),
  reservation: ZReservationItem,
});
export type UpdateReservationResponse = z.infer<
  typeof UpdateReservationResponse
>;

export type AddWalkInPayload = z.infer<typeof AddWalkInPayload>;
export const UpdateReservationPayload = AddReservationPayload.extend({
  id: z.string(),
});

export type UpdateReservationPayload = z.infer<typeof UpdateReservationPayload>;

export const UpdateReservationStatusPayload = z.object({
  id: z.string(),
  status: z.number().gte(1).lte(6).optional(),
});
export type UpdateReservationStatusPayload = z.infer<
  typeof UpdateReservationStatusPayload
>;
export const UpdateReservationStatusResponse = z.object({
  success: z.boolean(),
});
export type UpdateReservationStatusResponse = z.infer<
  typeof UpdateReservationStatusResponse
>;
