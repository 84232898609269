import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import { useTranslation } from 'next-i18next';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { ReservationItem } from '@/common/types/reservation';
import {
  addMinutes,
  isBefore,
  isEqual,
  roundToNearestMinutes,
  subMinutes,
} from 'date-fns';
import { useState, useMemo } from 'react';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import Skeleton from '@mui/material/Skeleton';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { maxBy } from 'lodash-es';
import { assertUnreachable } from '@/common/utils';

export const StatusSeat = [
  'occupiedTable',
  'upcomingClash',
  'outsideOfSeatWindow',
  'outsideOfSeatWindowAndOccupiedTable',
  'outsideOfSeatWindowAndUpcomingClash',
] as const;
export type StatusSeat = typeof StatusSeat[number];

const DisabledText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
  textDecoration: 'underline',
  textUnderlineOffset: '-40%',
  textDecorationSkipInk: 'none',
}));

const RightArrow = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(0.4)}`,
}));

export type TypeResolveOccupied = 'SEAT_CLASH' | 'DEPART_CURRENT_SEAT';

const getResrvMaxTimeSeat = (
  listResrv: ReservationItem[]
): ReservationItem | undefined => {
  return maxBy(listResrv, (it) =>
    addMinutes(it.rDateTime, (it.diningInterval || 0) / 60)
  );
};

const sortRersvWithTime = (listResrv: ReservationItem[]): ReservationItem[] => {
  if (!listResrv?.length) return [];

  if (listResrv?.length === 1) return listResrv;

  return [...listResrv].sort((resrvA, resrvB) =>
    isBefore(resrvA?.rDateTime, resrvB?.rDateTime) ? -1 : 1
  );
};

export type SeatConfirmationResolvePayload = {
  typeResolveOccupied?: TypeResolveOccupied | null;
};
export interface SeatConfirmationProps {
  data: ReservationItem;
  onClose?: (value: boolean) => void;
  onSubmit?: (submitPayload: SeatConfirmationResolvePayload) => void;
  loading: boolean;
  type: StatusSeat;
  listResrvClash?: ReservationItem[];
}
const SeatConfirmation = (props: SeatConfirmationProps) => {
  const { onSubmit, onClose, data, loading, type, listResrvClash } = props;
  const { formatDate } = useI18nTimeUtils();

  const [typeResolveOccupied, setTypeResolveOccupied] =
    useState<TypeResolveOccupied | null>(null);

  const { t } = useTranslation();
  const customerNameFmt = useCustomerNameFmt();
  const theme = useTheme();
  const { rDateTime: oldResrvDateTime } = data || {};

  const dateNow = new Date();
  let newTime = roundToNearestMinutes(dateNow, {
    nearestTo: 15,
  });
  // round down to nearest 15 minutes (to match backend rounding logic)
  if (dateNow < newTime) {
    newTime = subMinutes(newTime, 15);
  }

  const userName = useMemo(
    () => (data?.user ? customerNameFmt(data?.user) : '-'),
    [data?.user, customerNameFmt]
  );

  const handClose = () => {
    onClose?.(false);
  };
  const handleSeat = async () => {
    onSubmit?.({ typeResolveOccupied });
  };

  const getOccupiedClashContent = useCallbackRef(
    ({
      listResrvClash,
      outsideOfSeatWindow,
    }: {
      listResrvClash: ReservationItem[];
      outsideOfSeatWindow?: boolean;
    }) => {
      const resrvMaxTimeSeat = getResrvMaxTimeSeat(listResrvClash);
      if (!resrvMaxTimeSeat) return { content: '', title: '' };

      const maxTimeSeat = addMinutes(
        resrvMaxTimeSeat?.rDateTime,
        (resrvMaxTimeSeat?.diningInterval || 0) / 60
      );

      let content = '';

      if (outsideOfSeatWindow) {
        content =
          listResrvClash?.length > 1
            ? t(
                `Seating {{userName}} will result in a clash with {{clashCustomer}} others on the same table and update {{userName}} reservation time from`,
                {
                  clashCustomer: listResrvClash?.length,
                  userName,
                }
              )
            : resrvMaxTimeSeat?.user
            ? t(
                `Seating {{userName}} will result in a clash with {{clashCustomer}} on the same table and update {{userName}} reservation time from`,
                {
                  clashCustomer: customerNameFmt(resrvMaxTimeSeat?.user),
                  userName,
                }
              )
            : t(
                `Seating {{userName}} will result in a clash on the same table and update {{userName}} reservation time from`,
                {
                  userName,
                }
              );
      } else {
        content =
          listResrvClash?.length > 1
            ? t(
                `Seating {{userName}} will result in a clash with {{clashCustomer}} others on the same table and update {{userName}} reservation time from`,
                {
                  clashCustomer: listResrvClash?.length,
                  userName,
                }
              )
            : resrvMaxTimeSeat?.user
            ? t(
                `Seating {{userName}} will result in a clash with {{clashCustomer}} on the same table and update {{userName}} reservation time from`,
                {
                  clashCustomer: customerNameFmt(resrvMaxTimeSeat?.user),
                  userName,
                }
              )
            : t(
                `Seating {{userName}} will result in a clash on the same table and update {{userName}} reservation time from`,
                {
                  userName,
                }
              );
      }

      const title = isBefore(new Date(), maxTimeSeat)
        ? t(`Table is currently occupied until {{maxTimeSeat}}`, {
            maxTimeSeat: formatDate(maxTimeSeat, { en: 'h:mm a' }),
          })
        : t('Table is currently occupied');

      return {
        content,
        title,
      };
    }
  );

  const getUpcomingClashContent = useCallbackRef(
    ({
      listResrvClash,
      outsideOfSeatWindow,
    }: {
      listResrvClash: ReservationItem[];
      outsideOfSeatWindow?: boolean;
    }) => {
      const rersvWithTimeIncr = sortRersvWithTime(listResrvClash);

      if (!rersvWithTimeIncr?.length || !rersvWithTimeIncr?.[0])
        return {
          content: '',
          title: '',
        };

      let content = '';

      if (outsideOfSeatWindow) {
        content = rersvWithTimeIncr[0].user
          ? t(
              `Seating {{userName}} now will result in a clash with {{clashCustomer}} on the same table and update {{userName}} reservation time from`,
              {
                clashCustomer: customerNameFmt(rersvWithTimeIncr[0].user),
                userName,
              }
            )
          : t(
              `Seating {{userName}} now will result in a clash on the same table and update {{userName}} reservation time from`,
              {
                userName,
              }
            );
      } else {
        content = rersvWithTimeIncr[0].user
          ? t(
              `Seating {{userName}} now will result in a clash with {{clashCustomer}} on the same table. Proceed to seat and allow reservations to clash?`,
              {
                clashCustomer: customerNameFmt(rersvWithTimeIncr[0].user),
                userName,
              }
            )
          : t(
              `Seating {{userName}} now will result in a clash on the same table. Proceed to seat and allow reservations to clash?`,
              {
                userName,
              }
            );
      }

      const title = t(`Table has an upcoming reservation at {{upcomingTime}}`, {
        upcomingTime: formatDate(rersvWithTimeIncr[0].rDateTime, {
          en: 'h:mm a',
        }),
      });
      return {
        content,
        title,
      };
    }
  );

  const dataModal = useMemo(() => {
    let content = '';
    let title = '';
    switch (type) {
      case 'outsideOfSeatWindow':
        return {
          icon: (
            <>
              <Box
                sx={{
                  mb: 2,
                  background: theme.palette.primary.main,
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ChairOutlinedIcon sx={{ fontSize: 27, color: '#FFFFFF' }} />
              </Box>
            </>
          ),
          title: t(`This reservation is scheduled for {{reservationTime}}`, {
            reservationTime: formatDate(data?.rDateTime, {
              en: 'h:mm a',
            }),
          }),
          content: t(
            'Proceed to seat {{userName}} and update reservation time to now?',
            {
              userName,
            }
          ),
          button: t(`Seat Customer`),
        };
      case 'occupiedTable':
        if (!listResrvClash?.length) return null;

        ({ content, title } = getOccupiedClashContent({
          listResrvClash,
        }));

        return {
          icon: (
            <WarningTwoToneIcon
              sx={{ fontSize: 48, color: theme.palette.warning.main }}
            />
          ),
          title,
          content,
          button: t(`Confirm`),
        };

      case 'upcomingClash':
        if (!listResrvClash?.length) return null;

        ({ content, title } = getUpcomingClashContent({
          listResrvClash,
        }));

        return {
          icon: (
            <WarningTwoToneIcon
              sx={{ fontSize: 48, color: theme.palette.warning.main }}
            />
          ),
          title,
          content,
          button: t('Seat and Clash'),
        };

      case 'outsideOfSeatWindowAndOccupiedTable':
        if (!listResrvClash?.length) return null;

        ({ content, title } = getOccupiedClashContent({
          listResrvClash,
          outsideOfSeatWindow: true,
        }));

        return {
          icon: (
            <WarningTwoToneIcon
              sx={{ fontSize: 48, color: theme.palette.warning.main }}
            />
          ),
          title,
          content,
          button: t(`Confirm`),
        };

      case 'outsideOfSeatWindowAndUpcomingClash':
        if (!listResrvClash?.length) return null;

        ({ content, title } = getUpcomingClashContent({
          listResrvClash,
          outsideOfSeatWindow: true,
        }));

        return {
          icon: (
            <WarningTwoToneIcon
              sx={{ fontSize: 48, color: theme.palette.warning.main }}
            />
          ),
          title,
          content,
          button: t('Seat and Clash'),
        };
      default:
        assertUnreachable(type);
        return null;
    }
  }, [
    data?.rDateTime,
    type,
    t,
    theme.palette.primary.main,
    theme.palette.warning.main,
    listResrvClash,
    getUpcomingClashContent,
    getOccupiedClashContent,
    userName,
    formatDate,
  ]);

  if (!dataModal) return null;
  return (
    <Dialog
      open
      onClose={handClose}
      css={css`
        & > .MuiBackdrop-root {
          background: rgba(22, 28, 36, 0.72);
        }
        & .MuiDialog-paper {
          width: 600px;
        }
      `}
    >
      <DialogTitle
        css={css`
          padding: 14px 14px 0;
          text-align: end;
        `}
      >
        <IconButton onClick={handClose}>
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        css={css`
          text-align: center;
          padding: 4px 24px 16px;
        `}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          {loading ? (
            <Skeleton variant={'circular'}>
              <Avatar />
            </Skeleton>
          ) : (
            dataModal?.icon
          )}
        </Box>

        <Box>
          <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
            {loading ? <Skeleton variant={'text'} /> : dataModal?.title}
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], marginBottom: '8px' }}
          >
            {loading ? <Skeleton variant={'text'} /> : dataModal?.content}
          </Typography>
          {loading ? (
            <Skeleton variant={'text'} />
          ) : (
            <>
              {(type === 'outsideOfSeatWindow' ||
                type === 'outsideOfSeatWindowAndOccupiedTable' ||
                type === 'outsideOfSeatWindowAndUpcomingClash' ||
                type === 'occupiedTable') &&
                !isEqual(oldResrvDateTime, newTime) && (
                  <Typography variant="subtitle2">
                    <DisabledText>
                      {formatDate(oldResrvDateTime, {
                        en: 'EEE dd LLL',
                        zh: 'EEE LLL do',
                      })}
                      {`, ${formatDate(oldResrvDateTime, { en: 'h:mm a' })}`}
                    </DisabledText>
                    <RightArrow>{'->'}</RightArrow>
                    <>
                      {formatDate(newTime, {
                        en: 'EEE dd LLL',
                        zh: 'EEE LLL do',
                      })}
                      {`, ${formatDate(newTime, { en: 'h:mm a' })}`}
                    </>
                  </Typography>
                )}

              {(type === 'occupiedTable' ||
                type === 'outsideOfSeatWindowAndOccupiedTable') && (
                <FormControl sx={{ marginTop: '16px' }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={typeResolveOccupied}
                    onChange={(e, val) =>
                      setTypeResolveOccupied(val as TypeResolveOccupied)
                    }
                  >
                    <FormControlLabel
                      value="DEPART_CURRENT_SEAT"
                      control={<Radio />}
                      label={t(
                        'Seat new customer and depart currently seated customer'
                      )}
                    />
                    <FormControlLabel
                      value="SEAT_CLASH"
                      control={<Radio />}
                      label={t(
                        'Seat new customer and allow both reservations to clash'
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <SecondaryButton
          css={css`
            width: 50%;
          `}
          autoFocus
          onClick={handClose}
          disabled={loading}
        >
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          css={css`
            width: 50%;
          `}
          onClick={handleSeat}
          disabled={
            ((type === 'occupiedTable' ||
              type === 'outsideOfSeatWindowAndOccupiedTable') &&
              !typeResolveOccupied) ||
            loading
          }
        >
          {dataModal?.button}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default SeatConfirmation;
