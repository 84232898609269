import { z } from 'zod';
import { ZCustomerWithoutRef } from './customer.base';
import { ZReservationItem } from './reservation';

export const ZCustomer = ZCustomerWithoutRef.extend({
  reservations: z.array(ZReservationItem).optional().nullable(),
  // /**
  //  * Relationships
  //  */
  // @ApiProperty({ type: () => UserBrand, isArray: true, required: false })
  // @OneToMany(() => UserBrand, (userBrand) => userBrand.user, { persistence: false })
  // userBrands?: UserBrand[];

  // @ApiProperty({ type: () => Order, isArray: true, required: false })
  // @OneToMany(() => Order, (order) => order.user, { persistence: false })
  // orders?: Order[];
});
export type Customer = z.infer<typeof ZCustomer>;

export type CustomerSearchRes = z.infer<typeof ZCustomerSearchRes>;
export const ZCustomerSearchRes = z.object({
  total: z.number().gte(0),
  lastPage: z.number().gte(1),
  data: z.array(ZCustomer),
});
