import { z } from 'zod';
import { ZReservationItem } from './reservation';
import { ZTableInAreaWithoutRef } from './table-in-area.base';
import { ZTableWithoutRef } from './table.base';

export const ZTable = ZTableWithoutRef.extend({
  reservations: z.array(ZReservationItem).optional().nullable(),
  tableInAreas: z.array(ZTableInAreaWithoutRef).optional(),
});
export type Table = z.infer<typeof ZTable>;
