import { ReservationSegmentItem } from '@/common/types/segment';
import { atom, useSetAtom } from 'jotai';
import { Trans } from 'next-i18next';
import { ReactNode, useCallback } from 'react';

export type ReservationType = 'Reservation' | 'WalkIn';
export type ActionWithCustomerInfo = 'SEE_DETAIL' | 'SEARCH' | 'ADD';
export type TagItem = {
  id: string;
  title: string;
};

export const reservationTypeAtom = atom<ReservationType>('Reservation');
export const customerInfoActionAtom = atom<ActionWithCustomerInfo>('SEARCH');
export const emailNotifyAtom = atom(false);
export const smsNotifyAtom = atom(false);
export const automatedCallNotifyAtom = atom(false);
export const isErrorTimeExpireAtom = atom(false);
export const timeExpireSelectAtom = atom('24 hours');
export const currentPaymentRequestStatusAtom = atom<string>('pending');
export const timeScheduleSelectAtom = atom<string>('All Day');
export const isShowSearchBar = atom<boolean>(false);
export const stepActivePWA = atom<number>(0);
export const reservationTagsAtom = atom<TagItem[]>([]);
export const reservationSegmentsAtom = atom<ReservationSegmentItem>([]);

export const createTagsModalAtom = atom({
  isOpen: false,
  type: 'reservation',
  customerMcaId: '',
  customerId: '',
});
export const useResetState = () => {
  const setReservationType = useSetAtom(reservationTypeAtom);
  const setCustomerInfoActionType = useSetAtom(customerInfoActionAtom);
  const setEmailNotify = useSetAtom(emailNotifyAtom);
  const setSmsNotify = useSetAtom(smsNotifyAtom);
  const setAutomatedCallNotify = useSetAtom(automatedCallNotifyAtom);
  const setIsErrorTimeExpire = useSetAtom(isErrorTimeExpireAtom);
  const setTimeExpireSelect = useSetAtom(timeExpireSelectAtom);
  const setCurrentPaymentRequestStatus = useSetAtom(
    currentPaymentRequestStatusAtom
  );
  const setStepActivePWA = useSetAtom(stepActivePWA);
  const setReservationTags = useSetAtom(reservationTagsAtom);

  const resetState = useCallback(() => {
    setReservationType('Reservation');
    setCustomerInfoActionType('SEARCH');
    setEmailNotify(false);
    setSmsNotify(false);
    setAutomatedCallNotify(false);
    setIsErrorTimeExpire(false);
    setTimeExpireSelect('24 hours');
    setCurrentPaymentRequestStatus('pending');
    setStepActivePWA(0);
    setReservationTags([]);
  }, [
    setReservationType,
    setCustomerInfoActionType,
    setEmailNotify,
    setSmsNotify,
    setAutomatedCallNotify,
    setIsErrorTimeExpire,
    setTimeExpireSelect,
    setCurrentPaymentRequestStatus,
    setStepActivePWA,
    setReservationTags,
  ]);
  return resetState;
};

export const OCCASION_LABELS: Record<string, ReactNode> = {
  Casual: <Trans ns="occasion">Casual</Trans>,
  Birthday: <Trans ns="occasion">Birthday</Trans>,
  Anniversary: <Trans ns="occasion">Anniversary</Trans>,
  'Couple Date': <Trans ns="occasion">Couple Date</Trans>,
  Business: <Trans ns="occasion">Business</Trans>,
  Others: <Trans ns="occasion">Others</Trans>,
};

export const occasionLabels = (occasion: string) => {
  return OCCASION_LABELS[occasion] || occasion;
};
