import { atom, useAtom, useSetAtom } from 'jotai';
import { ReservationItem } from '@/common/types/reservation';
import {
  CancelReservationConfirmation,
  ResCancelReason,
} from './cancel-reservation-confirmation';
export type CancelResConfirmPayload = {
  reservation: ReservationItem;
  onClose?: () => void;
  onSubmit?: (reason?: ResCancelReason) => void;
};
const cancelResConfirmPayloadAtom = atom<CancelResConfirmPayload | null>(null);
export const useShowCancelResConfirmModal = () => {
  const setPayload = useSetAtom(cancelResConfirmPayloadAtom);
  return (payload: CancelResConfirmPayload) =>
    new Promise<[action: 'ABORTED' | 'CONFIRMED', reason?: ResCancelReason]>(
      (resolve) => {
        setPayload({
          ...payload,
          onClose: () => {
            payload.onClose?.();
            resolve(['ABORTED']);
            setPayload(null);
          },
          onSubmit: (reason) => {
            payload.onSubmit?.(reason);
            resolve(['CONFIRMED', reason]);
            setPayload(null);
          },
        });
      }
    );
};
export default function GlobalCancelResConfirmModal() {
  const [payload] = useAtom(cancelResConfirmPayloadAtom);
  if (!payload) return null;
  return (
    <CancelReservationConfirmation
      reservation={payload.reservation}
      onClose={payload.onClose || function () {}}
      onSubmit={payload.onSubmit || function () {}}
    />
  );
}
