import { z } from 'zod';
import { ZOccasion } from '@/common/types/occasion';
import { ZBrandWithoutRef } from '@/common/types/brand.base';
import { ZOutletWithoutRef } from '@/common/types/outlet.base';

export const ZMerchantReservation = z.object({
  brand: ZBrandWithoutRef,
  occasions: z.array(ZOccasion),
  outlet: ZOutletWithoutRef,
});

export type MerchantReservation = z.infer<typeof ZMerchantReservation>;

export const ZUpdateMerchantReservationPayload = z.object({
  benchmarkNumOfCovers: ZOutletWithoutRef.shape.benchmarkNumOfCovers,
  benchmarkNumOfReservations:
    ZOutletWithoutRef.shape.benchmarkNumOfReservations,
  defaultAllowEmailNotify: ZOutletWithoutRef.shape.defaultAllowEmailNotify,
  defaultAllowSmsNotify: ZOutletWithoutRef.shape.defaultAllowSmsNotify,
  defaultAutomatedCallNotify:
    ZOutletWithoutRef.shape.defaultAutomatedCallNotify,
  defaultConfirmationNotify: ZOutletWithoutRef.shape.defaultConfirmationNotify,
  defaultReminderNotify: ZOutletWithoutRef.shape.defaultReminderNotify,
  enableSMS: ZOutletWithoutRef.shape.enableSMS,
  enableEmail: ZOutletWithoutRef.shape.enableEmail,
  enableAutomatedCall: ZOutletWithoutRef.shape.enableAutomatedCall,
  typeShowTimeslots: ZOutletWithoutRef.shape.typeShowTimeslots,
  forwardingAddress: ZOutletWithoutRef.shape.forwardingAddress,
  onlineConfirmations: ZOutletWithoutRef.shape.onlineConfirmations,
  onlineReminder: ZOutletWithoutRef.shape.onlineReminder,
  reservationAutoSms: ZOutletWithoutRef.shape.reservationAutoSms,
  reservationCancellationPolicy:
    ZOutletWithoutRef.shape.reservationCancellationPolicy,
  reservationDiningInterval: ZOutletWithoutRef.shape.reservationDiningInterval,
  reservationGracePeriod: ZOutletWithoutRef.shape.reservationGracePeriod,
  reservationIntervalTime: ZOutletWithoutRef.shape.reservationIntervalTime,
  reservationLeadTime: ZOutletWithoutRef.shape.reservationLeadTime,
  reservationPolicy: ZOutletWithoutRef.shape.reservationPolicy,
  reservationSendEmailCopy: ZOutletWithoutRef.shape.reservationSendEmailCopy,
  reservationSendEmailCardGuarantee:
    ZOutletWithoutRef.shape.reservationSendEmailCardGuarantee,
  optionAutoReservationStatus:
    ZOutletWithoutRef.shape.optionAutoReservationStatus,
  confirmationNotificationEnabled:
    ZOutletWithoutRef.shape.confirmationNotificationEnabled,
});

export type UpdateMerchantReservationPayload = z.infer<
  typeof ZUpdateMerchantReservationPayload
>;

export enum OptionAutoStatus {
  OPTION_ONE = 1,
  OPTION_TWO = 2,
}
