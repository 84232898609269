import useV2Api from '@/common/hooks/use-v2-api';
import {
  Customer,
  CustomerSearchRes,
  ZCustomer,
  ZCustomerSearchRes,
} from '@/common/types/customer';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import React from 'react';
import { z } from 'zod';
import useCallbackRef from './use-callback-ref';
import { useDebounceFn } from './use-debounce';

type SortType = 'FIRST_NAME' | 'EMAIL' | 'PHONE';
const sortByIntMap: Record<SortType, number> = {
  FIRST_NAME: 0,
  EMAIL: 1,
  PHONE: 2,
};

// https://oddle-pm.atlassian.net/wiki/spaces/TD/pages/2498953286/OMS+APIs+to+support+Oddle+Reserve
export function useCustomers({
  searchText,
  pageSize = 20,
  sort = 'FIRST_NAME',
  isSortAscending = true,
  debounceMs = 300,
  options,
}: {
  searchText?: string;
  pageSize?: number;
  sort?: SortType;
  isSortAscending?: boolean;
  debounceMs?: number; // set to 0 to disable debounce
  options?: UseInfiniteQueryOptions<
    CustomerSearchRes & { pageNum: number },
    Error,
    CustomerSearchRes,
    CustomerSearchRes & { pageNum: number },
    Array<any>
  >;
} = {}) {
  const sortBy = sortByIntMap[sort];
  const v2Api = useV2Api();
  const queryFn = useDebounceFn(
    async ({ pageParam = 1 }: { pageParam?: number }) => {
      const { data } = await v2Api.get('/users/oms/search', {
        params: {
          searchText,
          pageSize,
          sortBy,
          isSortAscending,
          pageNo: pageParam,
        },
      });
      return {
        ...ZCustomerSearchRes.parse(data),
        pageNum: pageParam,
      };
    },
    debounceMs
  );

  const query = useInfiniteQuery({
    queryKey: ['useCustomers', searchText, pageSize, sort, isSortAscending],
    queryFn,
    getNextPageParam: (lastPage) =>
      lastPage.pageNum >= lastPage.lastPage ? undefined : lastPage.pageNum + 1,
    getPreviousPageParam: (firstPage) =>
      firstPage.pageNum <= 1 ? undefined : firstPage.pageNum - 1,
    ...options,
  });
  return {
    ...query,
    flatData: (query.data?.pages || []).flatMap(({ data }) => data),
    total: query.data?.pages[0]?.total,
  };
}

export function useCustomerDetail({ userId }: { userId: string | null }) {
  const v2Api = useV2Api();
  const query = useQuery({
    queryKey: ['useCustomerDetail', userId],
    queryFn: async () => {
      if (!userId) throw Error('invalid userId');
      const {
        data: { data },
      } = await v2Api.get(`/users/${userId}/reservations`);
      return ZCustomer.parse(data);
    },
    enabled: !!userId,
  });
  return { ...query };
}

export type CreateCustomerPayload = z.infer<typeof ZCreateCustomerPayload>;
export const ZCreateCustomerPayload = z.object({
  firstName: z.string(),
  lastName: z.string().optional(),
  salutation: z.string().nullish(),
  phone: z.string(),
  additionalPhone: z.string().optional(),
  organisationName: z.string().optional(),
  canSendEmailMarketing: z.boolean().optional(),
  email: z
    .union([z.string().length(0), z.string().email()])
    .optional()
    .nullable()
    .transform((e) => (e === '' ? undefined : e)),
  notesDiner: z.string().optional(),
  birthday: z.string().optional(),
  avatarBase64: z.string().optional(),
});
export function useAddCustomer() {
  const v2Api = useV2Api();
  const addCustomer = React.useMemo(
    () =>
      z
        .function()
        .args(ZCreateCustomerPayload)
        .implement(async (payload) => {
          const { data } = await v2Api.post('/users', payload);
          return ZCustomer.parse(data);
        }),
    [v2Api]
  );
  const mutation = useMutation({
    mutationFn: addCustomer,
  });
  return {
    ...mutation,
  };
}
export function useUpdateCustomer() {
  const v2Api = useV2Api();
  const updateCustomer = React.useMemo(
    () =>
      z
        .function()
        .args(ZCreateCustomerPayload.extend({ userId: z.string() }))
        .implement(async ({ userId, ...payload }) => {
          const { data } = await v2Api.put('/users/' + userId, payload);
          return ZCustomer.parse(data);
        }),
    [v2Api]
  );
  const mutation = useMutation({
    mutationFn: updateCustomer,
  });
  return {
    ...mutation,
  };
}

export function useDeleteCustomer() {
  const v2Api = useV2Api();
  const deleteCustomer = React.useMemo(
    () =>
      z
        .function()
        .args(z.object({ mcaId: z.string() }))
        .implement(async ({ mcaId }) => {
          await v2Api.delete('/users/' + mcaId);
          return;
        }),
    [v2Api]
  );
  const mutation = useMutation({
    mutationFn: deleteCustomer,
  });
  return {
    ...mutation,
  };
}

export const useCustomerNameFmt = () => {
  const isChinese = false;

  const formatter = useCallbackRef((customer: Customer | null | undefined) => {
    if (!customer) return '';

    const { salutation, firstName, lastName } = customer;
    if (isChinese) {
      // TODO: just a demo for now
      const fullName = [lastName, firstName].filter(Boolean).join('');
      return [salutation && salutation !== 'N/A' && `${salutation}.`, fullName]
        .filter(Boolean)
        .join(' ');
    }
    return [
      salutation && salutation !== 'N/A' && `${salutation}.`,
      firstName,
      lastName,
    ]
      .filter(Boolean)
      .join(' ');
  });

  return formatter;
};

export const useCustomerFirstName = () => {
  const isChinese = false;

  const formatter = useCallbackRef((customer: Customer | null | undefined) => {
    if (!customer) return '';

    const { salutation, firstName } = customer;
    if (isChinese) {
      return [salutation && salutation !== 'N/A' && `${salutation}.`, firstName]
        .filter(Boolean)
        .join(' ');
    }
    return [salutation && salutation !== 'N/A' && `${salutation}.`, firstName]
      .filter(Boolean)
      .join(' ');
  });

  return formatter;
};

const ZCustomerTagsCDPs = z.object({
  tags: z.array(z.string()).nullable(),
  mcaId: z.string(),
});

const ZCustomerTags = z
  .array(
    z.object({
      id: z.string().optional(),
      outletId: z.string(),
      title: z.string().nullable(),
      createdTimestamp: z.string().optional(),
      modifiedTimestamp: z.string().nullable().optional(),
      deletedTimestamp: z.string().nullable().optional(),
    })
  )
  .nullish();

export type CustomerTags = z.infer<typeof ZCustomerTags>;

export function useAddCustomerCDPTag() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZCustomerTagsCDPs)
      .implement(async ({ tags, mcaId }) => {
        const { data } = await v2Api.post(`/cdps/${mcaId}/tags`, { tags });
        return data;
      })
  );
}

export function useDeleteCustomerCDPTag() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZCustomerTagsCDPs)
      .implement(async ({ mcaId, tags }) => {
        await v2Api.delete(`/cdps/${mcaId}/tags`, { data: { tags } });
        return;
      })
  );
}
export function useGetAllCustomerTags() {
  const v2Api = useV2Api();

  return useQuery(['get-customer-tags-query'], async () => {
    const { data } = await v2Api.get('/users/tags');
    return ZCustomerTags.parse(data);
  });
}

export function useDeleteCustomerTag() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(
        z.object({
          tagId: z.string(),
        })
      )
      .implement(async ({ tagId }) => {
        await v2Api.delete(`/users/tags/${tagId}`);
        return;
      })
  );
}
