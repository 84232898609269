import { z } from 'zod';
import { ZAreaWithoutRef } from './area.base';
import { ZSummaryWithoutRef } from './monthly-reservation-summary.base';
import { ZServiceTimingWithoutRef } from './schedule.base';
import { ZTicketWithoutRef } from './ticket.base';

const ZServiceTiming = ZServiceTimingWithoutRef.extend({
  ticket: ZTicketWithoutRef.nullish(),
  areas: z.array(ZAreaWithoutRef).nullish(),
});

export const ZSummary = ZSummaryWithoutRef.extend({
  serviceTimingsBlocked: z.array(ZServiceTiming).nullish(),
  serviceTimingsRunning: z.array(ZServiceTiming).nullish(),
});
export type Summary = z.infer<typeof ZSummary>;

export const ZSummaryPayload = z.object({
  year: z.string(),
  month: z.string(),
});
export type SummaryPayload = z.infer<typeof ZSummaryPayload>;

export const ZSummaryResponse = z.array(ZSummary);
export type SummaryResponse = z.infer<typeof ZSummaryResponse>;
