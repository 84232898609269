import {
  PaymentStatusResponse,
  RefundStatus,
  TransactionSource,
  TransactionType,
} from '@/common/types/transaction.base';
import { PaymentStatus } from './transaction-types';
import {
  format,
  parse,
  subMinutes,
  differenceInMinutes,
  isAfter,
  isEqual,
  addDays,
  isBefore,
} from 'date-fns';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { useMemo } from 'react';
import { assertUnreachable, ValueOf } from '@/common/utils';
import { useTranslation } from 'next-i18next';
import { ReservationItem } from '@/common/types/reservation';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';

export const TYPE_CHARGE_WINDOW = {
  BEFORE: 'before',
  DURING: 'during',
  AFTER: 'after',
};

export default function useTransactionUtils() {
  const { t } = useTranslation();

  const getTransactionPaymentStatus = useCallbackRef(
    (
      status: PaymentStatusResponse | null | undefined,
      type: TransactionType | null | undefined,
      refundStatus: RefundStatus | null | undefined,
      transactionSource: TransactionSource | null | undefined
    ): PaymentStatus => {
      if (refundStatus === 'waiting_refund_result') {
        return 'Pending';
      } else {
        if (type === 'create') {
          switch (status) {
            case 'succeeded':
            case 'paid':
              return 'Paid';
            case 'expired':
              return 'Expired';
            case 'refunded':
              return transactionSource === 'System'
                ? 'System Refund'
                : 'Manual Refund';
            case 'requires_capture':
              return 'Failed';
            case 'failed':
              return 'Failed';
            case 'guaranteed':
              return 'Guaranteed';
            case 'charge':
              return transactionSource === 'System'
                ? 'System Charge'
                : 'Manual Charge';
            case 'no_charge':
              return transactionSource === 'System'
                ? 'System No Charge'
                : 'Manual No Charge';
            default:
              return 'Pending';
          }
        }
        if (type === 'refund') {
          switch (status) {
            case 'succeeded':
              return 'Refunded';
            case 'requires_capture':
              return 'Failed';
            case 'failed':
              return 'Failed';
            default:
              return 'Pending';
          }
        }
        return 'Pending';
      }
    }
  );

  const getChargeType = useCallbackRef(
    (
      reservation: ReservationItem | null | undefined
    ): ValueOf<typeof TYPE_CHARGE_WINDOW> => {
      const startTimeChargeWindow = reservation?.rDateTime;
      const endTimeChargeWindow =
        startTimeChargeWindow && addDays(startTimeChargeWindow, 7);
      let type = TYPE_CHARGE_WINDOW.BEFORE;
      if (!endTimeChargeWindow || !startTimeChargeWindow) {
        type = TYPE_CHARGE_WINDOW.BEFORE;
      } else if (
        (isAfter(new Date(), startTimeChargeWindow) ||
          isEqual(new Date(), startTimeChargeWindow)) &&
        (isBefore(new Date(), endTimeChargeWindow) ||
          isEqual(new Date(), endTimeChargeWindow))
      ) {
        type = TYPE_CHARGE_WINDOW.DURING;
      } else if (isBefore(new Date(), startTimeChargeWindow)) {
        type = TYPE_CHARGE_WINDOW.BEFORE;
      } else if (isAfter(new Date(), endTimeChargeWindow)) {
        type = TYPE_CHARGE_WINDOW.AFTER;
      }
      return type;
    }
  );

  const isChargeTime = useCallbackRef(
    (reservation: ReservationItem | null | undefined): boolean => {
      if (!reservation) {
        return false;
      }

      const reservationTime = reservation?.rDateTime;
      const refundWindow = Number(reservation.ticket?.refundWindow) * 60 * 1000;

      const startTimeCharge = reservationTime.getTime() - refundWindow;

      const endTimeCharge = addDays(reservationTime, 7);

      const currentDate = new Date();

      return (
        isAfter(currentDate, startTimeCharge) &&
        isBefore(currentDate, endTimeCharge)
      );
    }
  );

  const isRefundableTransaction = useCallbackRef(
    (statusTransaction: PaymentStatus) => {
      switch (statusTransaction) {
        case 'Paid':
        case 'Manual Charge':
        case 'System Charge':
          return true;
        case 'Expired':
        case 'Refunded':
        case 'Pending':
        case 'Failed':
        case 'Guaranteed':
        case 'Manual Refund':
        case 'System Refund':
        case 'Manual No Charge':
        case 'System No Charge':
        default:
          return false;
      }
    }
  );

  const isRefundableReservation = useCallbackRef(
    (reservation: ReservationItem) => {
      if (
        !reservation ||
        !reservation.ticket ||
        reservation.ticket.typePayment === TICKET_TYPE_PAYMENT['No Payment']
      ) {
        return false;
      }
      if (reservation?.reservationPaymentRequest?.type === 'OTHER_METHOD') {
        return false;
      }
      const refundWindow = Number.parseInt(
        reservation.ticket.refundWindow || '0'
      );
      if (refundWindow === 0) {
        return false;
      }
      const reservationDateTime = parse(
        `${reservation.reservationDate} ${reservation.reservationTime}`,
        'yyyy-MM-dd HH:mm:ss',
        new Date()
      );
      const currentTime = new Date();
      // has specific time: when the refundable is set more than 1 day
      // user can set the precise time in the day
      if (
        reservation.ticket.specificTime &&
        reservation.ticket.isBeforeSpecificTime
      ) {
        const refundableDateStr = format(
          subMinutes(reservationDateTime, refundWindow),
          'yyyy-MM-dd'
        );
        const refundableDateTime = parse(
          `${refundableDateStr} ${reservation.ticket.specificTime}`,
          'yyyy-MM-dd hh:mm a',
          new Date()
        );
        return currentTime < refundableDateTime;
      }
      return (
        differenceInMinutes(reservationDateTime, currentTime) > refundWindow
      );
    }
  );

  const generateStatusLabel = useCallbackRef((status: PaymentStatus) => {
    switch (status) {
      case 'Expired':
        return t('Expired');
      case 'Failed':
        return t('Failed');
      case 'Paid':
        return t('Paid');
      case 'Guaranteed':
        return t('Guaranteed');
      case 'Manual Charge':
        return t('Manual Charge');
      case 'System Charge':
        return t('System Charge');
      case 'Manual No Charge':
        return t('Manual No Charge');
      case 'System No Charge':
        return t('System No Charge');
      case 'Pending':
        return t('Pending');
      case 'Refunded':
        return t('Refunded');
      case 'Manual Refund':
        return t('Manual Refund');
      case 'System Refund':
        return t('System Refund');
      default:
        assertUnreachable(status);
        return '';
    }
  });

  const capitalizeStatusLabel = useCallbackRef(
    (status: PaymentStatusResponse) => {
      switch (status) {
        case 'paid':
          return 'Paid';
        case 'pending':
          return 'Pending';
        case 'expired':
          return 'Expired';
        case 'guaranteed':
          return 'Guaranteed';
        case 'failed':
        case 'refunded':
        case 'succeeded':
        case 'charge':
        case 'no_charge':
        case 'requires_capture':
        case 'requires_action':
        case 'waiting_payment_result':
        default:
          return '';
      }
    }
  );

  return useMemo(
    () => ({
      getTransactionPaymentStatus,
      isRefundableTransaction,
      isRefundableReservation,
      generateStatusLabel,
      capitalizeStatusLabel,
      getChargeType,
      isChargeTime,
    }),
    [
      getTransactionPaymentStatus,
      isRefundableTransaction,
      isRefundableReservation,
      generateStatusLabel,
      capitalizeStatusLabel,
      getChargeType,
      isChargeTime,
    ]
  );
}
