import { useEffect } from 'react';
import TypedEventEmitter, { EventMap } from 'typed-emitter';
import useCallbackRef from './use-callback-ref';

export default function useEmitterEvent<
  EvMap extends EventMap,
  EvName extends keyof EvMap
>(emitter: TypedEventEmitter<EvMap>, event: EvName, listener: EvMap[EvName]) {
  const listenerRef = useCallbackRef(listener);
  useEffect(() => {
    emitter.on(event, listenerRef);
    return () => {
      emitter.off(event, listenerRef);
    };
  }, [emitter, event, listenerRef]);
}
