import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import useV2Api from '@/common/hooks/use-v2-api';
import { z } from 'zod';
import { useCallback, useEffect, useMemo } from 'react';
import {
  AddReservationPayload,
  AddWalkInPayload,
  GetReservationsParams,
  GetReservationsResponse,
} from '@/feat/reservation/types';
import { ReservationItem, ZReservationItem } from '@/common/types/reservation';
import { ZOutlet, Outlet } from '@/common/types/outlet';
import {
  MerchantReservation,
  ZMerchantReservation,
  UpdateMerchantReservationPayload,
  ZUpdateMerchantReservationPayload,
} from '@/common/types/merchant-reservation';
import useCallbackRef from './use-callback-ref';
import { qClient } from '../lib/react-query';
import {
  SummaryPayload,
  SummaryResponse,
  ZSummaryPayload,
  ZSummaryResponse,
} from '../types/monthly-reservation-summary';
import { currentPaymentRequestStatusAtom } from '@/feat/reservation/state';
import { useAtomValue } from 'jotai';
import useSession from './use-session';
import { useDebounceFn } from './use-debounce';
import {
  ReservationSegmentItem,
  ZReservationSegmentItem,
} from '@/common/types/segment';

export function useReservations(args?: {
  params?: GetReservationsParams;
  config?: UseQueryOptions<
    GetReservationsResponse,
    Error,
    GetReservationsResponse,
    Array<any>
  >;
}) {
  const v2Api = useV2Api();
  const { data: sessionData } = useSession();
  const outletId = sessionData?.outlet?.id;
  const brandId = sessionData?.brand?.id;

  const fetchReservations = useMemo(
    () =>
      z
        .function()
        .args(GetReservationsParams.optional())
        .implement(async (params?: GetReservationsParams) => {
          const { data } = await v2Api.get('/reservations', {
            params: {
              ...params,
              debugStoreId: outletId,
              debugBrandId: brandId,
              appName: 'host_v3',
            },
          });
          return GetReservationsResponse.parse(data);
        }),
    [brandId, outletId, v2Api]
  );

  const getReservationsQueryKeys = useMemo(() => {
    if (!args?.params) {
      return ['get-reservations', 'get-reservations-query'];
    }
    const {
      date,
      keysearch,
      limit,
      page,
      serviceTimingId,
      status,
      startTime,
      endTime,
    } = args.params;
    return [
      'get-reservations',
      serviceTimingId,
      date,
      keysearch,
      status,
      limit,
      page,
      startTime,
      endTime,
    ];
  }, [args?.params]);

  const optimisticUpdate = useCallbackRef(
    (updater: <T extends GetReservationsResponse | undefined>(x: T) => T) =>
      qClient.setQueryData(getReservationsQueryKeys, updater)
  );

  const getReservationsQuery = useQuery(
    getReservationsQueryKeys,
    () => fetchReservations(args?.params),
    args?.config
  );
  return {
    ...getReservationsQuery,
    optimisticUpdate,
  };
}

export function useReservation({
  reservationId,
  config,
}: {
  reservationId: string | null | undefined;
  config?: UseQueryOptions<ReservationItem, Error, ReservationItem, Array<any>>;
}) {
  const v2Api = useV2Api();
  return useQuery(
    ['get-reservations-query', reservationId],
    async () => {
      if (!reservationId) throw Error('invalid reservationId');
      const { data } = await v2Api.get(`/reservations/${reservationId}`);
      return ZReservationItem.parse(data);
    },
    { enabled: !!reservationId, ...config }
  );
}

export function useReservationsByTable({
  tableId,
  status,
  config,
}: {
  tableId: string | null;
  status?: number;
  config?: UseQueryOptions<ReservationItem, Error, ReservationItem, Array<any>>;
}) {
  const v2Api = useV2Api();

  return useQuery(
    ['get-reservations-by-table-query', tableId],
    async () => {
      if (!tableId) throw Error('invalid tableId');
      const { data } = await v2Api.get(
        `/reservations?tableId=${tableId}&status=${status}`
      );

      return data.data.map((item: ReservationItem) =>
        ZReservationItem.parse(item)
      );
    },
    { enabled: !!tableId, ...config }
  );
}

export function useAddReservation(args?: {
  config?: UseMutationOptions<
    ReservationItem,
    Error,
    AddReservationPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(AddReservationPayload)
      .implement(async (addReservationPayload: AddReservationPayload) => {
        const { data } = await v2Api.post(
          '/reservations',
          addReservationPayload
        );
        return ZReservationItem.parse(data);
      }),
    args?.config
  );
}

export type UpdateReservationPayload = AddReservationPayload & { id: string };

export function useUpdateReservation(args?: {
  config?: UseMutationOptions<
    ReservationItem,
    Error,
    UpdateReservationPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  const currentPaymentRequestStatus = useAtomValue(
    currentPaymentRequestStatusAtom
  );

  return useMutation(
    z
      .function()
      .args(AddReservationPayload.extend({ id: z.string() }))
      .implement(async (reservation: UpdateReservationPayload) => {
        if (
          reservation?.paymentType === 'OTHER_METHOD' &&
          currentPaymentRequestStatus === 'pending' &&
          (reservation?.paymentStatus === 'paid' ||
            reservation?.paymentStatus === 'guaranteed')
        ) {
          await v2Api.put(`/reservations/${reservation.id}/payment-status`, {
            paymentStatus: reservation.paymentStatus,
          });
        }
        const { data } = await v2Api.put(
          `/reservations/${reservation.id}`,
          reservation
        );
        return ZReservationItem.parse(data);
      }),
    args?.config
  );
}

export function useDeleteReservation(args?: {
  config?: UseMutationOptions<boolean, Error, string, unknown>;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(z.string())
      .implement(async (id: string) => {
        await v2Api.delete(`/reservations/${id}`);
        return true;
      }),
    args?.config
  );
}

export function useMerchantReservation(args?: {
  config?: UseQueryOptions<
    MerchantReservation,
    Error,
    MerchantReservation,
    Array<any>
  >;
}) {
  const v2Api = useV2Api();
  const fetchMerchantReservation = useMemo(
    () =>
      z.function().implement(async () => {
        const { data } = await v2Api.get(`/masterdata/merchant-reservation`);
        return ZMerchantReservation.parse(data);
      }),
    [v2Api]
  );

  return useQuery(
    ['get-merchant-reservation-query'],
    () => fetchMerchantReservation(),
    args?.config
  );
}

export function useUpdateMerchantReservation(args?: {
  config?: UseMutationOptions<
    Outlet,
    Error,
    UpdateMerchantReservationPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZUpdateMerchantReservationPayload)
      .implement(async (reservation: UpdateMerchantReservationPayload) => {
        const { data } = await v2Api.post(
          `/outlets/reservation-settings`,
          reservation
        );
        return ZOutlet.parse(data);
      }),
    args?.config
  );
}

export function useOptimisticUpdateMerchantReservationConfig(
  updater: (
    original: MerchantReservation | undefined,
    payload: UpdateMerchantReservationPayload
  ) => MerchantReservation | undefined
) {
  const queryClient = useQueryClient();
  const queryKey = ['get-merchant-reservation-query'];
  return {
    onMutate: async (value: UpdateMerchantReservationPayload) => {
      await queryClient.cancelQueries({ queryKey });

      const previousValue =
        queryClient.getQueryData<MerchantReservation>(queryKey);

      queryClient.setQueryData<MerchantReservation>(queryKey, (old) =>
        updater(old, value)
      );

      return previousValue;
    },
  };
}

export function useReservationSummary(args: {
  params: SummaryPayload;
  config?: UseQueryOptions<SummaryResponse, Error, SummaryResponse, Array<any>>;
}) {
  const v2Api = useV2Api();
  const fetchSummary = useMemo(
    () =>
      z
        .function()
        .args(ZSummaryPayload)
        .implement(async (params) => {
          const { data } = await v2Api.get('/reservations/summary', {
            params,
          });
          return ZSummaryResponse.parse(data);
        }),
    [v2Api]
  );

  const getReservationsSummaryQueryKeys = useMemo(() => {
    const { month, year } = args.params;
    return ['get-reservation-summary-query', month, year];
  }, [args.params]);

  return useQuery(
    getReservationsSummaryQueryKeys,
    () => fetchSummary(args.params),
    args?.config
  );
}

export function useAddWalkIn(args?: {
  config?: UseMutationOptions<
    ReservationItem,
    Error,
    AddWalkInPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(AddWalkInPayload)
      .implement(async (addWalkInPayload: AddWalkInPayload) => {
        const { data } = await v2Api.post(
          '/reservations/create-walk-in',
          addWalkInPayload
        );
        return ZReservationItem.parse(data);
      }),
    args?.config
  );
}

export function useUpdateWalkIn(args?: {
  config?: UseMutationOptions<
    ReservationItem,
    Error,
    AddWalkInPayload & { id: string },
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(AddWalkInPayload.extend({ id: z.string() }))
      .implement(async (reservation: AddWalkInPayload & { id: string }) => {
        const { data } = await v2Api.put(
          `/reservations/${reservation.id}/walk-in`,
          reservation
        );
        return ZReservationItem.parse(data);
      }),
    args?.config
  );
}

export function useMakeNotificationCall() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(z.string())
      .implement(async (id: string) => {
        await v2Api.post(`/reservations/${id}/confirmation-call`);
      })
  );
}

const ZReservationTags = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
  })
);
export type ReservationTags = z.infer<typeof ZReservationTags>;

export function useReservationTags({
  params,
  config,
}: {
  params?: { searchText?: string };
  config?: UseQueryOptions<ReservationTags, Error, ReservationTags, Array<any>>;
}) {
  const v2Api = useV2Api();

  return useQuery(
    ['get-reservation-tags-query'],
    async () => {
      const { data } = await v2Api.get('/tags', {
        params,
      });
      return ZReservationTags.parse(data);
    },
    config
  );
}

const ZCreateReservationTagPayload = z.object({
  tag: z.string(),
});

export function useAddReservationTag() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZCreateReservationTagPayload)
      .implement(async (payload) => {
        const { data } = await v2Api.post('/tags', payload);
        return data;
      })
  );
}

export function useDeleteReservationTag() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(
        z.object({
          tagId: z.string(),
        })
      )
      .implement(async ({ tagId }) => {
        await v2Api.delete(`/tags/${tagId}`);
        return;
      })
  );
}

export function useGetReservationSegmentTag({
  customerIds = [],
  pageSize = 10,
  debounceMs = 300,
  options,
}: {
  customerIds?: string[];
  pageSize?: number;
  debounceMs?: number;
  options?: UseInfiniteQueryOptions<
    { data: ReservationSegmentItem; pageNum: number },
    Error,
    { data: ReservationSegmentItem; pageNum: number },
    { data: ReservationSegmentItem; pageNum: number },
    Array<any>
  >;
} = {}) {
  const v2Api = useV2Api();

  const queryFn = useDebounceFn(async ({ pageParam = 1 }) => {
    const startIndex = (pageParam - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const userIdsForPage = customerIds.slice(startIndex, endIndex);

    const customerIdsParam = userIdsForPage.join(',');

    const { data } = await v2Api.get(`/users/segments`, {
      params: {
        customerIds: customerIdsParam,
      },
    });

    const parsedData = ZReservationSegmentItem.parse(data);
    return {
      data: parsedData,
      pageNum: pageParam,
    };
  }, debounceMs);

  const query = useInfiniteQuery(
    ['reservationSegments', customerIds, pageSize],
    queryFn,
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.length * pageSize;
        return totalFetched < customerIds.length
          ? lastPage.pageNum + 1
          : undefined;
      },
      enabled: customerIds.length > 0,
    }
  );

  const fetchNextPage = useCallback(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  useEffect(() => {
    fetchNextPage();
  }, [fetchNextPage, query.data]);

  return {
    ...query,
    flatData: (query.data?.pages || []).flatMap(({ data }) => data),
  };
}
