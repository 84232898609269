import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { ReservationItem } from '@/common/types/reservation';
import useCustomerNotificationSettings from '@/common/hooks/use-customer-notification-settings';
import useTransactionUtils, {
  TYPE_CHARGE_WINDOW,
} from '@/feat/transaction/util';
import { formatCurrency } from '@/common/lib/money';
import { totalPaidReservationPayment } from '../../utils';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { useReservation } from '@/common/hooks/use-reservations';

export const messageRefundType = [
  'INTERNAL_REASON',
  'MESSAGE_TO_CUSTOMER',
] as const;
export type MessageRefundType = typeof messageRefundType[number];

export type ResCancelReason = {
  isSendEmailRefund: boolean;
  reason: string;
  refundMessage: string;
};

interface IProps {
  reservation: ReservationItem;
  onClose: () => void;
  onSubmit: (reason?: ResCancelReason) => void;
}
export const CancelReservationConfirmation = ({
  reservation,
  onClose,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation();
  const customerNameFmt = useCustomerNameFmt();
  const { smsNotify, emailNotify } =
    useCustomerNotificationSettings(reservation);
  const {
    isRefundableReservation,
    getChargeType,
    getTransactionPaymentStatus,
    isRefundableTransaction,
    isChargeTime,
  } = useTransactionUtils();
  const [isSendToCustomer, setIsSendToCustomer] = React.useState(false);
  const [messageRefund, setMessageRefund] = React.useState({
    reason: '',
    refundMessage: '',
  });
  const { data: reservationWithTransaction } = useReservation({
    reservationId: reservation.id,
  });
  const { transaction } = reservationWithTransaction || {};
  const locale = useLocaleCfg();
  const typeCharge = getChargeType(reservation);

  const transactionPaymentStatus = getTransactionPaymentStatus(
    transaction?.status,
    transaction?.type,
    transaction?.statusRefund,
    transaction?.source
  );
  const noPayment = React.useMemo(
    () =>
      reservation?.ticket?.typePayment === TICKET_TYPE_PAYMENT['No Payment'],
    [reservation]
  );

  const isRersvManualPayment = React.useMemo(
    () =>
      reservation?.reservationPaymentRequest?.type === 'OTHER_METHOD' ||
      (reservation?.reservationPaymentRequest?.type === 'ONLINE_PAYMENT_LINK' &&
        reservation?.reservationPaymentRequest?.status === 'pending'),
    [reservation]
  );

  const onChangeMessageRefund = (value: string, type: MessageRefundType) => {
    if (type === 'INTERNAL_REASON') {
      setMessageRefund((prev) => ({ ...prev, reason: value }));
    }

    if (type === 'MESSAGE_TO_CUSTOMER') {
      setMessageRefund((prev) => ({ ...prev, refundMessage: value }));
    }
  };

  const handleCancelReservation = () => {
    if (!reservation) return;
    if (!noPayment || !isRersvManualPayment) {
      return onSubmit({
        ...messageRefund,
        isSendEmailRefund: !!messageRefund.refundMessage,
      });
    }
    onSubmit();
  };
  const labelModal = React.useMemo(() => {
    const { isWalkIn, user } = reservation;
    const price = formatCurrency(
      totalPaidReservationPayment({
        pax: reservation.pax,
        price: reservation.ticket?.price,
        typePrice: reservation.ticket?.typePrice,
      }),
      locale
    );
    if (isWalkIn) {
      return {
        title: t('Cancel walk-in?'),
        content: user
          ? t('Are you sure you want to cancel {{customer}}’s walk-in?', {
              customer: customerNameFmt(user),
            })
          : t('Are you sure you want to cancel this walk-in?'),
        btnCancel: t('Cancel Walk-in'),
      };
    }
    // Case 1: Cancellation ticket free
    if (noPayment || transactionPaymentStatus === 'Pending') {
      return {
        title: t('Cancel Reservation?'),
        content: [
          t('Are you sure you want to cancel {{customer}}’s reservation?', {
            customer: customerNameFmt(user),
          }),
          smsNotify || emailNotify
            ? smsNotify && emailNotify
              ? t(
                  'Cancellation notification will be sent to customer via SMS and email.'
                )
              : smsNotify
              ? t('Cancellation notification will be sent to customer via SMS.')
              : t(
                  'Cancellation notification will be sent to customer via email.'
                )
            : t('No cancellation notification will be sent to customer.'),
        ].join(' '),
        btnCancel: t('Cancel'),
      };
    }
    // Case 2: Cancel Reservation ticket Price + Refund
    if (
      !isRersvManualPayment &&
      ((transactionPaymentStatus === 'Paid' &&
        isRefundableReservation(reservation)) ||
        isRefundableTransaction(transactionPaymentStatus))
    ) {
      return {
        title: t('Cancel and Refund Reservation?'),
        content: [
          t('Are you sure you want to cancel {{customer}}’s reservation?', {
            customer: customerNameFmt(user),
          }),
          t(
            'You are about to refund {{customer}}’s reservation for a payment of {{price}}.',
            { customer: user && customerNameFmt(user), price }
          ),
          smsNotify || emailNotify
            ? smsNotify && emailNotify
              ? t(
                  'Cancellation and refund notification will be sent to customer via SMS and email.'
                )
              : smsNotify
              ? t(
                  'Cancellation and refund notification will be sent to customer via SMS.'
                )
              : t(
                  'Cancellation and refund notification will be sent to customer via email.'
                )
            : t(
                'No cancellation and refund notification will be sent to customer.'
              ),
        ].join(' '),
        btnCancel: t('Cancel and Refund'),
      };
    }
    // Case 3: Cancel Reservation ticket Price + No Refund
    if (isRersvManualPayment && transactionPaymentStatus === 'Paid') {
      return {
        title: t('Cancel and Not Refund Reservation?'),
        content: [
          t('Are you sure you want to cancel {{customer}}’s reservation?', {
            customer: customerNameFmt(user),
          }),
          t(
            'You are not refunding {{customer}}’s reservation for a payment of {{price}}, if you wish to refund, head to the Transaction page.',
            { customer: user && customerNameFmt(user), price }
          ),
          smsNotify || emailNotify
            ? smsNotify && emailNotify
              ? t(
                  'Cancellation and no refund notification will be sent to customer via SMS and email.'
                )
              : smsNotify
              ? t(
                  'Cancellation and no refund notification will be sent to customer via SMS.'
                )
              : t(
                  'Cancellation and no refund notification will be sent to customer via email.'
                )
            : t(
                'No cancellation and no refund notification will be sent to customer.'
              ),
        ].join(' '),
        btnCancel: t('Cancel and Not Refund'),
      };
    }
    //  Case 4: Cancel Reservation ticket Guaranteed  + Charge
    if (
      (typeCharge === TYPE_CHARGE_WINDOW.DURING || isChargeTime(reservation)) &&
      transactionPaymentStatus === 'Guaranteed' &&
      !isRersvManualPayment
    ) {
      return {
        title: t('Cancel and Charge Reservation?'),
        content: [
          t('Are you sure you want to cancel {{customer}}’s reservation?', {
            customer: customerNameFmt(user),
          }),
          t(
            'You are about to charge {{customer}}’s reservation for a payment of {{price}}.',
            { customer: user && customerNameFmt(user), price }
          ),
          smsNotify || emailNotify
            ? smsNotify && emailNotify
              ? t(
                  'Cancellation and charge notification will be sent to customer via SMS and email.'
                )
              : smsNotify
              ? t(
                  'Cancellation and charge notification will be sent to customer via SMS.'
                )
              : t(
                  'Cancellation and charge notification will be sent to customer via email.'
                )
            : t(
                'No cancellation and charge notification will be sent to customer.'
              ),
        ].join(' '),
        btnCancel: t('Cancel and Charge'),
      };
    }
    // Case 5: Cancel Reservation ticket Guaranteed + No Charge
    if (
      transactionPaymentStatus === 'Guaranteed' &&
      (isRersvManualPayment || typeCharge !== TYPE_CHARGE_WINDOW.DURING)
    ) {
      return {
        title: t('Cancel and Not Charge Reservation?'),
        content: [
          t('Are you sure you want to cancel {{customer}}’s reservation?', {
            customer: customerNameFmt(user),
          }),
          t(
            'You are not charging {{customer}}’s reservation for a payment of {{price}}.',
            { customer: user && customerNameFmt(user), price }
          ),
          smsNotify || emailNotify
            ? smsNotify && emailNotify
              ? t(
                  'Cancellation and no charge notification will be sent to customer via SMS and email.'
                )
              : smsNotify
              ? t(
                  'Cancellation and no charge notification will be sent to customer via SMS.'
                )
              : t(
                  'Cancellation and no charge notification will be sent to customer via email.'
                )
            : t(
                'No cancellation and no charge notification will be sent to customer.'
              ),
        ].join(' '),
        btnCancel: t('Cancel and Not Charge'),
      };
    }
    return {
      title: t('Cancel Reservation?'),
      content: [
        user
          ? t('Are you sure you want to cancel {{customer}}’s reservation?', {
              customer: customerNameFmt(user),
            })
          : t('Are you sure you want to cancel this reservation?'),
        ' ',
        smsNotify || emailNotify
          ? smsNotify && emailNotify
            ? t(
                'Cancellation notification will be sent to customer via SMS and email.'
              )
            : smsNotify
            ? t('Cancellation notification will be sent to customer via SMS.')
            : t('Cancellation notification will be sent to customer via email.')
          : t('No cancellation notification will be sent to customer.'),
      ].join(' '),
      btnCancel: t('Cancel'),
    };
  }, [
    reservation,
    locale,
    noPayment,
    transactionPaymentStatus,
    isRersvManualPayment,
    isRefundableReservation,
    isRefundableTransaction,
    typeCharge,
    isChargeTime,
    t,
    customerNameFmt,
    smsNotify,
    emailNotify,
  ]);

  const renderPlaceHolder = () => {
    if (
      !isRersvManualPayment &&
      ((transactionPaymentStatus === 'Paid' &&
        isRefundableReservation(reservation)) ||
        isRefundableTransaction(transactionPaymentStatus))
    ) {
      return t('Internal Refund Reason');
    } else if (
      typeCharge === TYPE_CHARGE_WINDOW.DURING &&
      transactionPaymentStatus === 'Guaranteed' &&
      !isRersvManualPayment
    ) {
      return t('Internal Cancellation and Charge Reason');
    } else {
      return t('Internal Cancellation Reason');
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{
        '& > .MuiBackdrop-root': {
          background: 'rgba(22, 28, 36, 0.72)',
        },
        '& .MuiDialog-paper': {
          maxWidth: '900px',
        },
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box className="div-center" sx={{ p: 1 }}>
            <WarningTwoToneIcon
              sx={{ color: 'warning.main', mr: 1, fontSize: 24 }}
            />
            <Typography variant="h6">{labelModal.title}</Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          px: 3,
          py: 0,
        }}
      >
        <Typography variant="body1" sx={{ color: 'grey.600', mb: 3 }}>
          {labelModal.content}
        </Typography>

        <TextField
          value={messageRefund.reason}
          onChange={(e) => {
            const { value } = e.target;
            onChangeMessageRefund?.(value, 'INTERNAL_REASON');
          }}
          sx={{ mb: 1 }}
          rows={4}
          fullWidth
          multiline
          inputProps={{
            maxLength: 120,
          }}
          placeholder={renderPlaceHolder()}
        />
        <Box sx={{ textAlign: 'end', mb: 1 }}>
          <Typography variant="caption" sx={{ color: 'grey.500' }}>
            {messageRefund.reason?.length || 0}/120 {t('characters')}
          </Typography>
        </Box>
        {!noPayment && !isRersvManualPayment && (
          <FormGroup sx={{ ml: 1.5, mb: isSendToCustomer ? 4 : 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSendToCustomer}
                  onChange={() => {
                    if (isSendToCustomer) {
                      onChangeMessageRefund?.('', 'MESSAGE_TO_CUSTOMER');
                    }
                    setIsSendToCustomer(!isSendToCustomer);
                  }}
                />
              }
              label={
                reservation.ticket?.typePayment ===
                TICKET_TYPE_PAYMENT.CardGuarantee
                  ? t('Send a customised message to customer')
                  : t('Send a customised refund message to customer')
              }
            />
          </FormGroup>
        )}
        {isSendToCustomer && (
          <>
            <TextField
              value={messageRefund.refundMessage}
              onChange={(e) => {
                const { value } = e.target;
                onChangeMessageRefund?.(value, 'MESSAGE_TO_CUSTOMER');
              }}
              sx={{ mb: 1 }}
              rows={4}
              fullWidth
              multiline
              inputProps={{
                maxLength: 120,
              }}
              placeholder={
                reservation.ticket?.typePayment ===
                TICKET_TYPE_PAYMENT.CardGuarantee
                  ? t('Message to Customer')
                  : t('Refund Message to Customer')
              }
            />
            <Box sx={{ textAlign: 'end' }}>
              <Typography variant="caption" sx={{ color: 'grey.500' }}>
                {messageRefund.refundMessage?.length || 0}/120 {t('characters')}
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" sx={{ color: 'grey.800' }} onClick={onClose}>
          {t('Close')}
        </Button>
        <Button color="error" onClick={handleCancelReservation}>
          {labelModal.btnCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
