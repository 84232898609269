import pDebounce from 'p-debounce';
import { useEffect, useMemo, useState } from 'react';
import useCallbackRef from './use-callback-ref';

export const SEARCH_DEBOUNCE_TIMEOUT = 300;

function useDebounce<T>(value: T, delay = SEARCH_DEBOUNCE_TIMEOUT): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
export default useDebounce;

export function useDebounceFn(fn: (...args: any[]) => any, ms = 0) {
  const stableFn = useCallbackRef(fn);

  const debouncedFn = useMemo(() => {
    if (!ms) return stableFn;
    return pDebounce(stableFn, ms);
  }, [ms, stableFn]);

  return debouncedFn;
}
