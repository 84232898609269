import { z } from 'zod';

export const ZSummaryWithoutRef = z.object({
  totalReservations: z.number(),
  totalAdults: z.number(),
  totalChildren: z.number(),
  date: z.string(),
  message: z.string().optional(),
  isBlockOutDate: z.boolean().optional(),
  isSpecial: z.boolean().optional(),
  reason: z.string().optional(),
  name: z.string().optional().nullish(),
});
