import { ReservationItem } from '../types/reservation';
import { useMerchantReservation } from './use-reservations';
import { useMemo } from 'react';

export default function useCustomerNotificationSettings(
  reservation: ReservationItem | undefined
) {
  const { data: dataMerchantReservation } = useMerchantReservation();

  const notification = useMemo(() => {
    if (!reservation) {
      return {
        smsNotify: !!dataMerchantReservation?.outlet.enableSMS,
        emailNotify: !!dataMerchantReservation?.outlet.enableEmail,
        automatedCallNotify:
          !!dataMerchantReservation?.outlet.enableAutomatedCall,
      };
    }

    if (!reservation.user?.email) {
      return {
        smsNotify: !!reservation.allowSmsNotify,
        emailNotify: false,
        automatedCallNotify: !!reservation.allowAutomatedCallNotify,
      };
    }

    const {
      source,
      allowEmailNotify,
      allowSmsNotify,
      allowAutomatedCallNotify,
    } = reservation;

    switch (source) {
      case 'customer':
      case 'google':
        return {
          smsNotify: !!dataMerchantReservation?.outlet.defaultAllowSmsNotify,
          emailNotify:
            !!dataMerchantReservation?.outlet.defaultAllowEmailNotify,
          automatedCallNotify:
            !!dataMerchantReservation?.outlet.defaultAutomatedCallNotify,
        };
      case 'restaurant':
        return {
          smsNotify: !!allowSmsNotify,
          emailNotify: !!allowEmailNotify,
          automatedCallNotify: !!allowAutomatedCallNotify,
        };
      default:
        return {
          smsNotify: false,
          emailNotify: false,
          automatedCallNotify: false,
        };
    }
  }, [reservation, dataMerchantReservation]);

  return notification;
}
