import { atom, useAtom, useSetAtom } from 'jotai';
import SeatConfirmation, {
  SeatConfirmationProps,
  SeatConfirmationResolvePayload,
  StatusSeat,
} from './seat-confirmation';
import { ReservationItem } from '@/common/types/reservation';
import useCallbackRef from '@/common/hooks/use-callback-ref';

export type SeatConfirmationPayload = {
  reservation: ReservationItem;
  type: StatusSeat;
  listResrvClash?: ReservationItem[];
  onClose?: () => void;
  onSubmit?: SeatConfirmationProps['onSubmit'];
};

const seatConfirmationPayloadAtom = atom<SeatConfirmationPayload | null>(null);

export const useShowSeatConfirmationModal = () => {
  const setPayload = useSetAtom(seatConfirmationPayloadAtom);
  return useCallbackRef(
    (payload: SeatConfirmationPayload) =>
      new Promise<['ABORTED'] | ['CONFIRMED', SeatConfirmationResolvePayload]>(
        (resolve) => {
          setPayload({
            ...payload,
            onClose: () => {
              payload.onClose?.();
              resolve(['ABORTED']);
              setPayload(null);
            },
            onSubmit: (resolvePayload) => {
              payload.onSubmit?.(resolvePayload);
              resolve(['CONFIRMED', resolvePayload]);
              setPayload(null);
            },
          });
        }
      )
  );
};

export default function GlobalSeatConfirmationModal() {
  const [payload] = useAtom(seatConfirmationPayloadAtom);

  if (!payload) return null;
  return (
    <SeatConfirmation
      data={payload.reservation}
      type={payload.type}
      loading={false}
      onClose={payload.onClose || function () {}}
      onSubmit={payload.onSubmit || function () {}}
      listResrvClash={payload?.listResrvClash}
    />
  );
}
