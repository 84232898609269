import EE from 'events';
import TypedEventEmitter from 'typed-emitter';
import { ChangeStatusReservationPayload } from '../hooks/use-change-reservation-status';
import { ReservationItem } from '../types/reservation';

export type GlobalEvents = {
  CLASHING_RESERVATIONS_DEPARTED: (
    departedReservationIds?: string[]
  ) => any;
  RESERVATION_STATUS_CHANGED: (args: {
    payload: ChangeStatusReservationPayload;
  }) => any;
  RESERVATION_STATUS_CHANGED_WITH_RESULT: (args: {
    payload: ChangeStatusReservationPayload;
    updated: ReservationItem;
  }) => any;

  SINGLE_RESERVATION_UPDATED: (reservationId: string) => any;
  [key: `SINGLE_RESERVATION_UPDATED::${string}`]: () => any;

  SINGLE_TABLE_UPDATED: (data: {
    tableId: string;
    status: number;
  }) => any;
  [key: `SINGLE_TABLE_UPDATED::${string}`]: (data: {
    tableId: string;
    status: number;
  }) => any;

  RESERVATIONS_UPDATED: () => any;
  MQTT_RECONNECTING: () => any;
  MQTT_RECONNECTED: () => any;
};

export const globalEmitter = new EE() as TypedEventEmitter<GlobalEvents>;
