import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import useV2Api from '@/common/hooks/use-v2-api';
import {
  ReservationItem,
  SeatingAbortError,
  ZReservationItem,
} from '@/common/types/reservation';
import {
  API_RSRV_STATUS_MAP,
  API_TABLE_STATUS_MAP,
  ReservationStatus,
  RESERVATION_STATUS_MAP,
  WalkinStatus,
  allowableTransitions,
} from '../types/reservation-status-flow';
import { cloneDeep, isEqual } from 'lodash-es';
import useCallbackRef from './use-callback-ref';
import { ZTable } from '../types/table';
import {
  addMinutes,
  format,
  isAfter,
  isBefore,
  isToday,
  roundToNearestMinutes,
  subMinutes,
} from 'date-fns';
import {
  SeatConfirmationPayload,
  useShowSeatConfirmationModal,
} from '../../feat/reservation/components/global-seat-confirmation-modal';
import { globalEmitter } from '../lib/global-emitter';
import { ResCancelReason } from '@/feat/reservation/components/confirmation/cancel-reservation-confirmation';
import { useShowCancelResConfirmModal } from '@/feat/reservation/components/confirmation/global-cancel-reservation-confirmation';
import { useShowSnackbarMessage } from '../components/snack-bar';
import useEmitterEvent from './use-emitter-event';
import { useTranslation } from 'next-i18next';
import { useCustomerNameFmt } from './use-customers';
import { getReservationStartEndTime } from '@/feat/reservation/utils';

const checkForSeatingOutsideOfSeatWindow = (
  reservation: ReservationItem
): boolean => {
  const { rDateTime } = reservation;
  const gracePeriod = (reservation.serviceTiming?.gracePeriod || 0) / 60;
  const dateNow = new Date();
  const isBeforeSeatWindow = isBefore(addMinutes(dateNow, 30), rDateTime);
  const isAfterSeatWindow = isAfter(
    dateNow,
    addMinutes(rDateTime, gracePeriod + 30)
  );

  return isBeforeSeatWindow || isAfterSeatWindow;
};

const checkForOccupiedTable = (
  listReservation: ReservationItem[]
): ReservationItem[] => {
  return listReservation?.filter((resrv) => resrv.rStatus === 'R::SEATED');
};

const checkForUpcomingClashTable = (
  reservationSeating: ReservationItem,
  listReservation: ReservationItem[]
): ReservationItem[] => {
  const dateNow = new Date();
  let newTime = roundToNearestMinutes(dateNow, {
    nearestTo: 15,
  });

  if (dateNow < newTime) {
    newTime = subMinutes(newTime, 15);
  }

  const { start, end } = getReservationStartEndTime({
    ...reservationSeating,
    reservationDate: format(newTime, 'yyyy-MM-dd'),
    reservationTime: format(newTime, 'HH:mm:ss'),
  });

  const listResrvUpcoming = listReservation?.filter((resrv) => {
    const { start: rStart, end: rEnd } = getReservationStartEndTime(resrv);

    return (
      (resrv.rStatus === 'R::BOOKED' || resrv.rStatus === 'R::CONFIRMED') &&
      reservationSeating.id !== resrv.id &&
      rEnd > start &&
      rStart < end
    );
  });

  return listResrvUpcoming;
};

type resCheckReservationSeating = {
  departAllOccupiedTable?: boolean;
};

const useCheckReservationSeating = () => {
  const v2Api = useV2Api();
  const showSeatConfirmationModal = useShowSeatConfirmationModal();

  const handleShowSeatConfirmationModal = useCallbackRef(
    async (payload: SeatConfirmationPayload & { abortErrMessage: string }) => {
      const { reservation, type, listResrvClash, abortErrMessage } = payload;
      const [action, resolvePayload] = await showSeatConfirmationModal({
        reservation,
        type,
        listResrvClash,
      });
      if (action === 'ABORTED') throw new SeatingAbortError(abortErrMessage);

      const typeResolveOccupied = resolvePayload?.typeResolveOccupied;
      const departAllOccupiedTable =
        (type === 'occupiedTable' ||
          type === 'outsideOfSeatWindowAndOccupiedTable') &&
        typeResolveOccupied === 'DEPART_CURRENT_SEAT';

      return {
        departAllOccupiedTable,
      };
    }
  );

  return useCallbackRef(
    async (
      payload: ChangeStatusReservationPayload & {
        newMutable: ReservationItem;
        optimisticCb: () => void;
      }
    ): Promise<resCheckReservationSeating> => {
      const { newStatus, reservation } = payload;
      const { rDateTime, tables, isWalkIn } = reservation;

      if (isWalkIn) return {};

      if (newStatus !== 'R::SEATED' || !isToday(rDateTime)) {
        throw new Error(
          'Reservation must be Today and status should be SEATED'
        );
      }

      const tableId = tables?.[0]?.id;

      if (!tableId) throw Error('invalid tableId');

      const formatToday = format(new Date(), 'yyyy-MM-dd');
      const { data } = await v2Api.get(
        `/tables/${tableId}/reservations?date=${formatToday}`
      );
      const listReservation = ZTable.parse(data).reservations;

      const isOutSideSeatingWindow =
        checkForSeatingOutsideOfSeatWindow(reservation);

      if (listReservation?.length) {
        const listResrvSeated = checkForOccupiedTable(listReservation);

        // handle seat reservation when exist occupied table
        if (listResrvSeated?.length) {
          if (isOutSideSeatingWindow)
            return await handleShowSeatConfirmationModal({
              reservation,
              type: 'outsideOfSeatWindowAndOccupiedTable',
              listResrvClash: listResrvSeated,
              abortErrMessage: 'Seat on occupied table: User aborted',
            });

          return await handleShowSeatConfirmationModal({
            reservation,
            type: 'occupiedTable',
            listResrvClash: listResrvSeated,
            abortErrMessage: 'Seat on occupied table: User aborted',
          });
        }

        const listResrvUpcoming = checkForUpcomingClashTable(
          reservation,
          listReservation
        );

        // handle seat reservation when exist upcoming reservation
        if (listResrvUpcoming?.length) {
          if (isOutSideSeatingWindow)
            return await handleShowSeatConfirmationModal({
              reservation,
              type: 'outsideOfSeatWindowAndUpcomingClash',
              listResrvClash: listResrvUpcoming,
              abortErrMessage:
                'Seat on table with upcoming clash: User aborted',
            });

          return await handleShowSeatConfirmationModal({
            reservation,
            type: 'upcomingClash',
            listResrvClash: listResrvUpcoming,
            abortErrMessage: 'Seat on table with upcoming clash: User aborted',
          });
        }
      }

      /* 
    * handle seat reservation when outside of seating window
      Seating more than 30 min before reservation 
      Seating more than 30min after reservation start + grace period (defined in schedule settings)
  */
      if (isOutSideSeatingWindow) {
        return await handleShowSeatConfirmationModal({
          reservation,
          type: 'outsideOfSeatWindow',
          abortErrMessage: 'Seating outside of seat window: User aborted',
        });
      }
      return {};
    }
  );
};

export type ChangeStatusReservationPayload = {
  newStatus: ReservationStatus;
  reservation: ReservationItem;
  resCancelHideConfirmation?: boolean;
  optimisticCallback?: (reservation: ReservationItem) => void;
};
export default function useChangeReservationStatus(args?: {
  config?: UseMutationOptions<
    ReservationItem,
    Error,
    ChangeStatusReservationPayload,
    unknown
  >;
}) {
  const showCancelResConfirmModal = useShowCancelResConfirmModal();
  const checkReservationSeating = useCheckReservationSeating();
  const v2Api = useV2Api();
  const changeStatusReservationMutation = useMutation(async (payload) => {
    const {
      newStatus,
      reservation,
      optimisticCallback: _optimisticCb,
      resCancelHideConfirmation,
    } = payload;
    const { id, tables, statusStr, tableStatusStr } = reservation;
    const tableId = tables?.[0]?.id;

    const isAllowed = (): boolean => {
      // refunding a CANCELLED reservation
      if (
        reservation.rStatus === 'R::CANCELLED' &&
        newStatus === 'R::CANCELLED'
      )
        return true;
      return (allowableTransitions(reservation) || []).includes(newStatus);
    };
    if (!isAllowed())
      throw Error(
        `Status transition not allowed: ${reservation.rStatus} -> ${newStatus}`
      );

    const newMutable = cloneDeep(reservation);
    const optimisticCb = (() => {
      let prev = cloneDeep(reservation);
      return () => {
        if (!_optimisticCb) return;
        if (isEqual(prev, newMutable)) return;
        prev = cloneDeep(newMutable);
        // re-parse to re-generate enhanced keys
        _optimisticCb(ZReservationItem.parse(newMutable));
      };
    })();

    let isDepartAllOccupiedTable = false;
    let resCancelReason: ResCancelReason | undefined;

    if (newStatus === 'R::CANCELLED' && !resCancelHideConfirmation) {
      const [action, reason] = await showCancelResConfirmModal({
        reservation,
      });
      if (action === 'ABORTED') return reservation;
      if (reason) resCancelReason = reason;
    }
    if (newStatus === 'R::SEATED') {
      try {
        const { departAllOccupiedTable } = await checkReservationSeating({
          ...payload,
          optimisticCb,
          newMutable,
        });
        isDepartAllOccupiedTable = !!departAllOccupiedTable;
      } catch (err) {
        if (err instanceof SeatingAbortError) {
          console.warn(err);
          return reservation;
        }
        throw err;
      }
    }

    const newStatuses = RESERVATION_STATUS_MAP[newStatus];

    // TODO: handle PENDING and EXPIRED case
    if (!Object.keys(newStatuses).length)
      throw Error(
        `Status transition not supported yet: ${reservation.rStatus} -> ${newStatus}`
      );

    if (newStatuses?.apiRsrvStatus) {
      const status = API_RSRV_STATUS_MAP[newStatuses.apiRsrvStatus];
      newMutable.status = status;
      optimisticCb();

      if (isDepartAllOccupiedTable) {
        await v2Api.put(`/reservations/${id}/status`, {
          status,
          isDepartAll: true,
        });
      } else if (resCancelReason) {
        // refund and cancel
        await v2Api.put(`/reservations/${id}/status`, {
          status,
          ...resCancelReason,
        });
      } else if (newStatuses.apiRsrvStatus !== statusStr) {
        await v2Api.put(`/reservations/${id}/status`, {
          status,
        });
      }
    }

    if (newMutable?.tables?.[0]) {
      newMutable.tables[0].status = newStatuses?.apiTableStatus
        ? API_TABLE_STATUS_MAP[newStatuses.apiTableStatus]
        : null; // change table status to null to indicate unknown status to avoid falsely assuming status in backend
      optimisticCb();
    }
    if (
      newStatuses.apiTableStatus &&
      newStatuses.apiTableStatus !== tableStatusStr
    ) {
      if (!tableId) throw Error('no tables found');
      await v2Api.put(`/tables/${tableId}/status`, {
        status: API_TABLE_STATUS_MAP[newStatuses.apiTableStatus],
      });
    }

    // emit event to refetch list reservation after depart all current reservation seated
    if (isDepartAllOccupiedTable) {
      globalEmitter.emit('CLASHING_RESERVATIONS_DEPARTED');
    }

    globalEmitter.emit('RESERVATION_STATUS_CHANGED', {
      payload,
    });

    // refetch reservation to get updated reservation time (eg: seat outside of seat window) and table status
    const updated = ZReservationItem.parse(
      (await v2Api.get('/reservations/' + id)).data
    );
    globalEmitter.emit('RESERVATION_STATUS_CHANGED_WITH_RESULT', {
      payload,
      updated,
    });
    return updated;
  }, args?.config);

  return changeStatusReservationMutation;
}

export const useReservationStatusNotif = () => {
  const showSnackbar = useShowSnackbarMessage();
  const { t } = useTranslation();
  const customerNameFmt = useCustomerNameFmt();
  const showNotif = (msg: string | null | undefined) => {
    if (!msg) return;
    showSnackbar(msg);
  };

  useEmitterEvent(
    globalEmitter,
    'RESERVATION_STATUS_CHANGED_WITH_RESULT',
    ({ payload, updated }) => {
      if (payload.newStatus === payload.reservation.rStatus) return;

      const customerName = updated.user ? customerNameFmt(updated.user) : null;
      const tableName = updated.tables?.[0]?.name || '';

      if (updated.isWalkIn) {
        const walkinMsgs: Record<WalkinStatus, string> = {
          'R::SEATED': customerName
            ? t(`{{customerName}} seated on Table {{tableName}}.`, {
                customerName,
                tableName,
              })
            : t(`Customer seated on Table {{tableName}}`, { tableName }),
          'R::BILL': customerName
            ? t(
                `{{customerName}} on Table {{tableName}} is requesting for bill.`,
                {
                  customerName,
                  tableName,
                }
              )
            : t(`Customer on Table {{tableName}} is requesting for bill.`, {
                tableName,
              }),
          'R::COMPLETED': customerName
            ? t(`{{customerName}}’s walk-in has been completed.`, {
                customerName,
              })
            : t(`Walk-in has been completed.`),
          'R::OVERSTAYED': '',
          // 'R::CANCELLED': customerName
          //   ? t(`{{customerName}}’s walk-in has been cancelled.`, {
          //       customerName,
          //     })
          //   : t(`Walk-in has been cancelled.`),
        };
        return showNotif(walkinMsgs[updated.rStatus as WalkinStatus]);
      }
      const msgs: Record<ReservationStatus, string> = {
        'R::PENDING': '',
        'R::BOOKED': '',
        'R::CONFIRMED': customerName
          ? t(`{{customerName}}’s reservation has been confirmed`, {
              customerName,
            })
          : t('Reservation has been confirmed'),
        'R::LATE': '',
        'R::SEATED': customerName
          ? t(`{{customerName}} seated on Table {{tableName}}.`, {
              customerName,
              tableName,
            })
          : t(`Customer seated on Table {{tableName}}`, { tableName }),
        'R::BILL': customerName
          ? t(
              `{{customerName}} on Table {{tableName}} is requesting for bill.`,
              {
                customerName,
                tableName,
              }
            )
          : t(`Customer on Table {{tableName}} is requesting for bill.`),
        'R::OVERSTAYED': '',
        'R::NO_SHOW': customerName
          ? t(`{{customerName}}’s reservation is marked as no show.`, {
              customerName,
            })
          : t(`Reservation is marked as no show.`),
        'R::COMPLETED': customerName
          ? t(`{{customerName}}’s reservation has been completed.`, {
              customerName,
            })
          : t(`Reservation has been completed.`),
        'R::CANCELLED': customerName
          ? t(`{{customerName}}’s reservation has been cancelled.`, {
              customerName,
            })
          : t(`Reservation has been cancelled.`),
        'R::EXPIRED': '',
      };
      return showNotif(msgs[updated.rStatus]);
    }
  );
};
