import CloseTwoTone from '@mui/icons-material/CloseTwoTone';
import WarningTwoTone from '@mui/icons-material/WarningTwoTone';
import SentimentSatisfiedAltTwoTone from '@mui/icons-material/SentimentSatisfiedAltTwoTone';
import PersonOffTwoTone from '@mui/icons-material/PersonOffTwoTone';
import MonetizationOnTwoTone from '@mui/icons-material/MonetizationOnTwoTone';
import ChairTwoTone from '@mui/icons-material/ChairTwoTone';
import TimerTwoTone from '@mui/icons-material/TimerTwoTone';
import PendingTwoTone from '@mui/icons-material/PendingTwoTone';
import DoneAllTwoTone from '@mui/icons-material/DoneAllTwoTone';
import CheckTwoTone from '@mui/icons-material/CheckTwoTone';
import CancelTwoTone from '@mui/icons-material/CancelTwoTone';
import { css } from '@emotion/react';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Palette,
  Popover,
  SvgIcon,
  SvgIconProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import {
  ReservationStatus,
  allowableTransitions,
} from '../types/reservation-status-flow';
import { Trans } from 'next-i18next';
import useCallbackRef from '../hooks/use-callback-ref';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import { ReservationItem } from '../types/reservation';
import { GREY } from '@/minimals.cc/theme/palette';
import useChangeReservationStatus from '@/common/hooks/use-change-reservation-status';
import { Extend, useIsoLayoutEffect } from '../utils';

const CaretDownSvg = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M5.80654 7.80667L7.5332 9.53333C7.7932 9.79333 8.2132 9.79333 8.4732 9.53333L10.1999 7.80667C10.6199 7.38667 10.3199 6.66667 9.72654 6.66667H6.2732C5.67987 6.66667 5.38654 7.38667 5.80654 7.80667Z"
      fill="inherit"
    />
  </SvgIcon>
);

export const RESERVATION_STATUS_ICONS: Record<ReservationStatus, ReactElement> =
  {
    'R::PENDING': <PendingTwoTone />,
    'R::BOOKED': <CheckTwoTone />,
    'R::CONFIRMED': <DoneAllTwoTone />,
    'R::LATE': <TimerTwoTone />,
    'R::SEATED': <ChairTwoTone />,
    'R::BILL': <MonetizationOnTwoTone />,
    'R::OVERSTAYED': <ChairTwoTone />,
    'R::NO_SHOW': <PersonOffTwoTone />,
    'R::COMPLETED': <SentimentSatisfiedAltTwoTone />,
    'R::CANCELLED': <CancelTwoTone />,
    'R::EXPIRED': <WarningTwoTone />,
  };
export const RESERVATION_STATUS_LABELS: Record<
  ReservationStatus,
  ReactElement
> = {
  'R::PENDING': <Trans>Pending</Trans>,
  'R::BOOKED': <Trans>Booked</Trans>,
  'R::CONFIRMED': <Trans>Confirmed</Trans>,
  'R::LATE': <Trans>Late</Trans>,
  'R::SEATED': <Trans>Seated</Trans>,
  'R::BILL': <Trans>Bill Requested</Trans>,
  'R::OVERSTAYED': <Trans>Overstayed</Trans>,
  'R::NO_SHOW': <Trans>No Show</Trans>,
  'R::COMPLETED': <Trans>Completed</Trans>,
  'R::CANCELLED': <Trans>Cancelled</Trans>,
  'R::EXPIRED': <Trans>Expired</Trans>,
};

const ReservationStatusLabels = ({
  item,
  showFullText,
}: {
  item: ReservationItem;
  showFullText?: boolean;
}) => {
  const { rStatus } = item;
  // confirmed by customer when `confirmByRestaurant` === false
  const isConfirmedByRestaurant =
    item.confirmByRestaurant === null ||
    item.confirmByRestaurant === undefined ||
    item.confirmByRestaurant === true;
  switch (rStatus) {
    case 'R::PENDING':
      return <Trans>Pending</Trans>;
    case 'R::BOOKED':
      return <Trans>Booked</Trans>;
    case 'R::CONFIRMED': {
      if (showFullText) {
        return isConfirmedByRestaurant ? (
          <Trans>Confirmed By Merchant</Trans>
        ) : (
          <Trans>Confirmed By Customer</Trans>
        );
      }
      return <Trans>Confirmed</Trans>;
    }
    case 'R::LATE':
      return <Trans>Late</Trans>;
    case 'R::SEATED':
      return <Trans>Seated</Trans>;
    case 'R::BILL':
      return showFullText ? <Trans>Bill Requested</Trans> : <Trans>Bill</Trans>;
    case 'R::OVERSTAYED':
      return <Trans>Overstayed</Trans>;
    case 'R::NO_SHOW':
      return <Trans>No Show</Trans>;
    case 'R::COMPLETED':
      return <Trans>Completed</Trans>;
    case 'R::CANCELLED':
      return <Trans>Cancelled</Trans>;
    case 'R::EXPIRED':
      return <Trans>Expired</Trans>;
    default:
      return null;
  }
};

export const RESERVATION_STATUS_COLORS = ({
  info,
  warning,
  primary,
  success,
  error,
}: Palette): Record<
  ReservationStatus,
  { bg: string; bgHover: string; text: string }
> => ({
  'R::PENDING': { bg: GREY[500], bgHover: GREY[500], text: 'white' },
  'R::BOOKED': { bg: info.main, bgHover: info.dark, text: 'white' },
  'R::CONFIRMED': { bg: success.main, bgHover: success.dark, text: 'white' },
  'R::LATE': { bg: warning.dark, bgHover: warning.darker, text: 'white' },
  'R::SEATED': { bg: primary.main, bgHover: primary.dark, text: 'white' },
  'R::BILL': { bg: warning.main, bgHover: warning.dark, text: 'white' },
  'R::OVERSTAYED': { bg: error.main, bgHover: error.dark, text: 'white' },
  'R::NO_SHOW': {
    bg: 'rgba(145, 158, 171, 0.24)',
    bgHover: GREY[400],
    text: GREY[700],
  },
  'R::COMPLETED': {
    bg: 'rgba(145, 158, 171, 0.24)',
    bgHover: GREY[400],
    text: GREY[700],
  },
  'R::CANCELLED': {
    bg: 'rgba(145, 158, 171, 0.24)',
    bgHover: 'rgba(145, 158, 171, 0.24)',
    text: GREY[700],
  },
  'R::EXPIRED': {
    bg: 'rgba(145, 158, 171, 0.24)',
    bgHover: 'rgba(145, 158, 171, 0.24)',
    text: GREY[700],
  },
});

export const RESERVATION_STATUS_BTN_VARIANTS = [
  'VERTICAL',
  'COMPACT',
  'COMPACT_SQUARE',
  'HORIZONTAL',
] as const;
export type ReservationStatusBtnVariant =
  typeof RESERVATION_STATUS_BTN_VARIANTS[number];

export const RESERVATION_STATUS_BTN_DROPDOWN_VARIANTS = [
  'MODAL',
  'INLINE',
] as const;
export type ReservationStatusBtnDropdownVariant =
  typeof RESERVATION_STATUS_BTN_DROPDOWN_VARIANTS[number];

export type ReservationStatusBtnProps = Extend<
  ButtonProps,
  {
    reservationItem: ReservationItem;
    btnVariant?: ReservationStatusBtnVariant;
    className?: string;
    onChange?: (newStatus: ReservationStatus) => any;
    dropdownVariant?: ReservationStatusBtnDropdownVariant;
    allowChange?: boolean;
    showFullText?: boolean;
    // TODO: This is a work around for timeline view, where the click event is
    // is not fired properly by the library
    enableOnTouchEnd?: boolean;
  }
>;
export function ReservationStatusBtn({
  reservationItem,
  btnVariant = 'HORIZONTAL',
  className,
  onChange,
  dropdownVariant = 'MODAL',
  allowChange = false,
  showFullText,
  sx = [],
  enableOnTouchEnd,
  ...ButtonProps
}: ReservationStatusBtnProps) {
  const { palette } = useTheme();
  const { rStatus } = reservationItem;
  const readOnly = useMemo(
    () => !allowChange || !allowableTransitions(reservationItem)?.length,
    [allowChange, reservationItem]
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallbackRef(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => setAnchorEl(event.currentTarget)
  );
  const handleClose = useCallbackRef(() => setAnchorEl(null));
  const externalSx = React.useMemo(
    () => (Array.isArray(sx) ? sx : [sx]) as any,
    [sx]
  );
  const commonBtnProps = React.useMemo<ButtonProps>(
    () => ({
      className: clsx(
        'ReservationStatusBtn-root',
        `ReservationStatusBtn-${btnVariant}`,
        className
      ),
      onClick: readOnly ? undefined : handleClick,
      onTouchEnd: readOnly
        ? undefined
        : enableOnTouchEnd
        ? handleClick
        : undefined,
      endIcon: !readOnly ? (
        <CaretDownSvg style={{ marginRight: -6, marginLeft: -4 }} />
      ) : undefined,
      sx: [
        {
          pointerEvents: readOnly ? 'none' : undefined,
        },
        btnVariant === 'HORIZONTAL' && {
          minHeight: 32,
          minWidth: 144,
          '> .MuiTypography-root': {
            flex: 1,
            textAlign: 'left',
          },
        },
        btnVariant === 'VERTICAL' && {
          minWidth: 52,
          px: 1,
          height: 106,
          flexDirection: 'column',
          '> .MuiButton-startIcon': {
            '> .MuiSvgIcon-root': { fontSize: 28 },
            margin: 0,
          },
          '> .MuiButton-endIcon': {
            margin: 0,
            position: 'absolute',
            bottom: 0,
          },
          '> .MuiTypography-root': {
            display: 'none',
          },
        },
        (btnVariant === 'COMPACT' || btnVariant === 'COMPACT_SQUARE') && {
          height: 48,
          width: 48,
          minWidth: 0,
          px: 1,
          borderRadius: btnVariant === 'COMPACT_SQUARE' ? '4px' : '50%',
          '> .MuiButton-startIcon': {
            '> .MuiSvgIcon-root': { fontSize: 28 },
            margin: 0,
          },
          '> .MuiTypography-root': {
            display: 'none',
          },
          '> .MuiButton-endIcon': {
            display: 'none',
          },
        },
        ...externalSx,
      ],
    }),
    [btnVariant, className, readOnly, handleClick, enableOnTouchEnd, externalSx]
  );
  const colors = RESERVATION_STATUS_COLORS(palette)[rStatus];
  const tooltipEnable = btnVariant === 'COMPACT' || btnVariant === 'VERTICAL';

  const btnElm = (
    <Button
      {...commonBtnProps}
      startIcon={RESERVATION_STATUS_ICONS[rStatus]}
      css={css`
        color: ${colors.text};
        background: ${colors.bg};
        ${showFullText ? '' : 'max-width: 136px;'}
        :hover {
          background: ${colors.bgHover};
        }
        .MuiButton-startIcon > svg {
          font-size: 16px;
          margin-bottom: -2px;
        }
      `}
      {...ButtonProps}
    >
      <Typography variant="overline">
        <ReservationStatusLabels
          item={reservationItem}
          showFullText={showFullText}
        />
      </Typography>
    </Button>
  );

  return (
    <>
      {tooltipEnable ? (
        <Tooltip title={RESERVATION_STATUS_LABELS[rStatus]} arrow>
          <Box sx={{ display: 'inline-block' }}>{btnElm}</Box>
        </Tooltip>
      ) : (
        btnElm
      )}
      {(() => {
        if (readOnly) return;
        const listItems = allowableTransitions(reservationItem)?.map(
          (status) => {
            const colors = RESERVATION_STATUS_COLORS(palette)[status];
            const handleClick = () => {
              if (onChange) onChange(status);
              handleClose();
            };
            return (
              <ListItemButton
                key={status}
                onClick={handleClick}
                onTouchEnd={enableOnTouchEnd ? handleClick : undefined}
                css={css`
                  min-height: 48px;
                `}
              >
                <ListItemIcon>
                  <Box
                    css={css`
                      border-radius: 50%;
                      width: 24px;
                      height: 24px;
                      background: ${colors.bg};
                      color: ${colors.text};
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      svg {
                        font-size: 14px;
                      }
                    `}
                  >
                    {RESERVATION_STATUS_ICONS[status]}
                  </Box>
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">
                    {RESERVATION_STATUS_LABELS[status]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            );
          }
        );
        if (dropdownVariant === 'INLINE')
          return (
            <Popover
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              elevation={12}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List
                subheader={
                  <ListSubheader>
                    <Typography variant="overline" color="textSecondary">
                      <Trans>CHANGE TO</Trans>
                    </Typography>
                  </ListSubheader>
                }
                css={css`
                  margin-top: -8px;
                `}
              >
                {listItems}
              </List>
            </Popover>
          );
        return (
          <Dialog
            onClose={handleClose}
            open={open}
            css={css`
              .MuiDialog-paper {
                width: 600px;
                max-width: 95vw;
              }
            `}
          >
            <DialogTitle>
              <Trans>Change Status To</Trans>
              <IconButton
                size="large"
                onClick={handleClose}
                onTouchEnd={enableOnTouchEnd ? handleClose : undefined}
                css={css`
                  position: absolute;
                  right: 8px;
                  top: 12px;
                `}
              >
                <CloseTwoTone />
              </IconButton>
            </DialogTitle>
            <List
              css={css`
                margin-top: -16px;
                .MuiListItem-root {
                  padding-left: 24px;
                  padding-right: 24px;
                }
              `}
            >
              {listItems}
            </List>
          </Dialog>
        );
      })()}
    </>
  );
}

export type AutoRsrvStatusBtnProps = Extend<
  ReservationStatusBtnProps,
  {
    onStatusChange?: (newReservation: ReservationItem) => any;
    onStatusChangeSuccess?: (newReservation: ReservationItem) => any;
    onStatusChangeFail?: (error: any) => any;
    disableAutoApiHandling?: boolean;
    // TODO: This is a work around for timeline view, where the click event is
    // is not fired properly by the library
    enableOnTouchEnd?: boolean;
  }
>;
export const AutoRsrvStatusBtn = ({
  reservationItem: _rItem,
  onStatusChange,
  onStatusChangeSuccess,
  onStatusChangeFail,
  onChange,
  enableOnTouchEnd,
  ...props
}: AutoRsrvStatusBtnProps) => {
  const [reservation, setReservation] = React.useState(_rItem);
  const { mutateAsync: changeStatus } = useChangeReservationStatus();

  const handleChange = useCallbackRef(async (newStatus: ReservationStatus) => {
    onChange?.(newStatus);

    try {
      const apiUpdated = await changeStatus({
        reservation,
        newStatus,
        optimisticCallback: (updated) => {
          setReservation(updated);
          onStatusChange?.(updated);
        },
      });
      onStatusChangeSuccess?.(apiUpdated);
    } catch (err) {
      // undo optimistic update
      setReservation(_rItem);
      onStatusChangeFail?.(err);
    }
  });

  useIsoLayoutEffect(() => {
    setReservation(_rItem);
  }, [_rItem]);

  return (
    <>
      <ReservationStatusBtn
        reservationItem={reservation}
        onChange={handleChange}
        allowChange
        enableOnTouchEnd={enableOnTouchEnd}
        {...props}
      />
    </>
  );
};

export default ReservationStatusBtn;
